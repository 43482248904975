import { CircularProgress } from '@material-ui/core'

import { Filters } from 'components'

import { useFetch } from 'hooks'

import constants from 'constants/index'
import * as service from 'service'

const ChurnFilter = ({ filter }) => {
  const { response, isLoading } = useFetch(
    service.dponet.churns.listResponsibles,
    {},
  )

  return (
    <Filters filter={filter}>
      <input textfieldinput="true" label="Identificador" name="id" />
      <input textfieldinput="true" label="Nome da empresa" name="companyName" />
      <input textfieldinput="true" label="Documento" name="companyDocument" />
      <input
        textfieldinput="true"
        label="Nº do contrato"
        name="contractIdentifier"
      />
      <select
        autocompleteselectinput="true"
        name="responsibleId"
        label="Colaborador responsável"
        optionvalues={response?.data?.users || []}
        InputProps={{
          endAdornment: isLoading ? <CircularProgress size={20} /> : null,
        }}
      />
      <input textfieldinput="true" label="Ticket" name="ticketId" />
      <input
        textfieldinput="true"
        label="Data da solicitação"
        name="ticketDate"
      />
      <select
        multipleselectinput="true"
        label="Status"
        name="statusIn"
        optionvalues={constants.churn.STATUSES}
      />
    </Filters>
  )
}

export default ChurnFilter
