import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFilterTags } from 'hooks'

import {
  Box,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  Tooltip,
} from '@material-ui/core'

import styles from './styles'
import helpers from 'helpers'
import constants from 'constants/index'

import { isEmpty } from 'lodash'
import { XCircle as XCircleIcon } from 'react-feather'

import PropTypes from 'prop-types'

const useStyles = makeStyles(styles)

const FilterTags = ({ filter = {}, notSpecific = false }) => {
  const classes = useStyles()
  const [filtersList, setFiltersList] = useState([])

  const { t } = useTranslation()
  const {
    companies,
    departments,
    titularCategories,
    templateCompanies,
    legalFrameworks,
  } = useFilterTags()

  useEffect(() => {
    const handleFiltersList = () => {
      const dateFilters = ['startDateGteq', 'endDateGteq', 'createdAt']
      const list = []

      if (!isEmpty(filter.filters)) {
        Object.keys(filter.filters).map((filterKey) => {
          const functionsList = {
            statusId: helpers.filterTags.statusIdValue(
              filter.filters[filterKey],
            ),
            companyId: helpers.filterTags.filterObject(
              filter.filters[filterKey],
              companies,
              true,
            ),
            shareTypes: helpers.filterTags.filterObjects(
              filter.filters[filterKey],
              constants.dataProcess.SHARE_TYPE_OPTIONS,
            ),
            id: filter.filters[filterKey],
            name: filter.filters[filterKey],
            document: helpers.filterTags.documentValue(
              filter.filters[filterKey],
            ),
            departmentId: helpers.filterTags.filterObject(
              filter.filters[filterKey],
              departments,
              true,
            ),
            companyKind: helpers.filterTags.filterObject(
              filter.filters[filterKey],
              constants.dataProcess.COMPANY_KIND_FILTER_OPTIONS,
            ),
            fragilityId: helpers.filterTags.filterObjects(
              filter.filters[filterKey],
              constants.dataProcess.FRAGILITY_FILTER_OPTIONS,
            ),
            titularCategories: helpers.filterTags.filterObjects(
              filter.filters[filterKey],
              titularCategories,
            ),
            dataTypes: helpers.filterTags.filterObjects(
              filter.filters[filterKey],
              constants.dataProcess.DATA_TYPE_OPTIONS,
            ),
            originCompanies: helpers.filterTags.filterObjects(
              filter.filters[filterKey],
              templateCompanies,
            ),
            legalFrameworks: helpers.filterTags.filterObjects(
              filter.filters[filterKey],
              legalFrameworks,
            ),
            sourceDescription: helpers.filterTags.sourceDescriptionText(
              filter.filters[filterKey],
            ),
          }

          const type = t(`filterChips.${filterKey}`)
          let value = ''

          if (filterKey === 'statusId' && filter.filters[filterKey] === null) {
            value = 'Todos'
          } else if (dateFilters.includes(filterKey)) {
            value = helpers.formatters.date(filter.filters[filterKey])
          } else {
            value = functionsList[filterKey]
          }

          return list.push({ type: type, value: value, key: filterKey })
        })
      }

      setFiltersList(list)
    }

    handleFiltersList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filter.filters,
    companies,
    departments,
    titularCategories,
    templateCompanies,
  ])

  const handleRemoveTag = (key) => {
    if (notSpecific) return filter.setFilters({})

    setFiltersList((filtersList) =>
      filtersList.filter((filter) => filter.key !== key),
    )

    const deleteFilter = (filters) => {
      delete filters[key]
      return filters
    }

    filter.setFilters(deleteFilter)
    filter.setReload((reload) => reload + 1)
  }

  return (
    <Box className={classes.root}>
      {!notSpecific && (
        <Box className={classes.text}>
          <Typography variant="body1">Filtro(s):</Typography>
        </Box>
      )}
      <Grid container spacing={2} className={classes.tagsGroup}>
        {notSpecific ? (
          <Grid item>
            <Box className={classes.tag}>
              <Typography variant="body2" className={classes.tagText}>
                Filtro aplicado
              </Typography>
              <IconButton
                aria-label="remove filter"
                size="small"
                color="inherit"
                className={classes.tagIconButton}
                onClick={() => handleRemoveTag('')}
              >
                <XCircleIcon className={classes.tagIcon} />
              </IconButton>
            </Box>
          </Grid>
        ) : (
          <>
            {filtersList.map(
              (filter) =>
                !!filter.value && (
                  <Grid item key={filter.key}>
                    <Box className={classes.tag}>
                      <Tooltip title={filter.value} placement="bottom-start">
                        <Typography variant="body2" className={classes.tagText}>
                          {filter.type}: {filter.value}
                        </Typography>
                      </Tooltip>
                      <IconButton
                        aria-label="remove filter"
                        size="small"
                        color="inherit"
                        className={classes.tagIconButton}
                        onClick={() => handleRemoveTag(filter.key)}
                      >
                        <XCircleIcon className={classes.tagIcon} />
                      </IconButton>
                    </Box>
                  </Grid>
                ),
            )}
          </>
        )}
      </Grid>
    </Box>
  )
}

FilterTags.propTypes = {
  filters: PropTypes.array,
  notSpecific: PropTypes.bool,
}

export default FilterTags
