import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'

import { DatePicker } from '@material-ui/pickers'
import { Box, Grid, TextField } from '@material-ui/core'

import { Card, Label, ButtonComponent } from 'components'

import PropTypes from 'prop-types'
import helpers from 'helpers'
import * as service from 'service'

import { useSnackbar } from 'hooks'

import schema from './schema'
import constants from 'constants/index'
import theme from 'theme'

const MeetingStep = ({
  churn,
  setLoading,
  handleNextStep,
  handleBackStep,
  show,
}) => {
  const snackbar = useSnackbar()

  const churnMeeting = churn?.churnMeeting

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: {
      date: churnMeeting?.date
        ? moment(churnMeeting?.date).format('YYYY-MM-DD')
        : null,
      email: churnMeeting?.email || '',
      name: churnMeeting?.name || '',
      observation: churnMeeting?.observation || '',
    },
  })

  const onSubmit = async (data) => {
    if (show) return handleNextStep()
    setLoading(true)

    try {
      const method = churnMeeting ? 'update' : 'create'

      await service.dponet.churnMeetings[method]({
        churnId: churn?.id,
        churnMeetingId: churnMeeting?.id,
        name: data?.name,
        email: data?.email,
        observation: data?.observation,
        date: data?.date ? moment(data?.date).format('YYYY-MM-DD') : null,
      })

      snackbar.open({
        message: 'Reunião realizada com sucesso!',
        variant: 'success',
      })

      handleNextStep()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro ao tentar realizar a reunião',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="meeting-form">
      <Card title="Agendamento da reunião" paddingCard={0}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Controller
              as={
                <DatePicker
                  label="Data da reunião"
                  format="DD/MM/yyyy"
                  error={!!errors?.date}
                  helperText={errors?.date?.message}
                  onChange={(newValue) => ({
                    value: newValue,
                  })}
                  fullWidth
                  disabled={show}
                />
              }
              control={control}
              name="date"
              mode="onChange"
            />
          </Grid>
          {constants.churn.MEETING_CONTROL_ITEMS.map((item, index) => (
            <Grid key={index} item xs={12} md={item?.gridMd}>
              <Controller
                as={
                  <TextField
                    type={item?.inputMode}
                    color="primary"
                    variant="outlined"
                    label={item?.label}
                    error={!!errors?.[item?.controlName]}
                    helperText={errors?.[item?.controlName]?.message}
                    fullWidth
                    disabled={show}
                  />
                }
                control={control}
                name={item?.controlName}
                mode="onChange"
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Label title="Descrição (opcional)">
              <Controller
                as={
                  <TextField
                    type="text"
                    color="primary"
                    variant="outlined"
                    placeholder="Exemplo: cliente não quis participar da reunião."
                    error={!!errors?.observation}
                    helperText={errors?.observation?.message}
                    fullWidth
                    multiline
                    disabled={show}
                    maxRows={12}
                    minRows={8}
                  />
                }
                control={control}
                name="observation"
                mode="onChange"
              />
            </Label>
          </Grid>
        </Grid>
      </Card>
      <Box
        display="flex"
        justifyContent="flex-end"
        gridGap={theme.spacing(2)}
        mt={2}
      >
        <ButtonComponent
          variant="outlined"
          onClick={handleBackStep}
          title="voltar"
          type="button"
        />
        <ButtonComponent
          variant="contained"
          color="primary"
          title={`${
            show ? '' : churnMeeting ? 'Alterar e' : 'Salvar e'
          } Avançar`}
          type="submit"
          form="meeting-form"
        />
      </Box>
    </form>
  )
}

MeetingStep.propTypes = {
  churn: PropTypes.object,
  setLoading: PropTypes.func,
  handleNextStep: PropTypes.func,
  handleBackStep: PropTypes.func,
  handleBackPage: PropTypes.func,
  show: PropTypes.bool,
}

export default MeetingStep
