import companyList from './companyList'
import companySupplierList from './companySupplierList'
import complaintList from './complaintList'
import dashboardList from './dashboardList'
import dataProcessList from './dataProcessList'
import departmentList from './departmentList'
import diagnosticsList from './diagnosticsList'
import documentList from './documentList'
import documentTemplateList from './documentTemplateList'
import financialList from './financialList'
import incidentList from './incidentList'
import lastUpdatesList from './lastUpdateList'
import nonComplianceReportList from './nonComplianceReportList'
import privacyPolicyList from './privacyPolicyList'
import profileList from './profileList'
import questionnaireList from './questionnaireList'
import ripdList from './ripdList'
import userList from './userList'

const clientProfileLists = {
  companyList,
  companySupplierList,
  complaintList,
  dashboardList,
  dataProcessList,
  departmentList,
  diagnosticsList,
  documentList,
  documentTemplateList,
  financialList,
  incidentList,
  lastUpdatesList,
  nonComplianceReportList,
  privacyPolicyList,
  profileList,
  questionnaireList,
  ripdList,
  userList,
}

export default clientProfileLists
