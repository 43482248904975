import PropTypes from 'prop-types'
import { Box, TablePagination, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { useFetch, usePagination } from 'hooks'

import { Card, LoadingFeedback } from 'components'

import * as service from 'service'
import { ContractTable } from '../'

const ContractCard = ({ sellerId }) => {
  const theme = useTheme()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading } = useFetch(
    service.dponet.paymentContract.lists,
    {
      sellerIdOrPartnerId: sellerId,
      perPage,
      page,
    },
    [perPage, page],
  )

  return (
    <Card title="Contratos">
      <LoadingFeedback open={isLoading} />
      <ContractTable
        paymentContracts={response?.data?.paymentContracts || []}
      />
      <Box px={2} display="flex" justifyContent="flex-end">
        <TablePagination
          component="div"
          count={response?.data?.meta?.totalCount || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          rowsPerPage={perPage}
          rowsPerPageOptions={[5, 10, 25, 100]}
          labelRowsPerPage={isDesktop ? 'Por página' : ''}
          nextIconButtonProps={{ size: 'small' }}
          backIconButtonProps={{ size: 'small' }}
        />
      </Box>
    </Card>
  )
}

ContractCard.propTypes = {
  sellerId: PropTypes.string.isRequired,
}

export default ContractCard
