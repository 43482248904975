import api from 'service/api'
import axios from 'axios'

import * as service from 'service'
import { getBaseURL } from 'service/env'

const baseURL = getBaseURL('dponet')

const dponetAPI = api.create('dponet')

const lists = async (params) => {
  return await dponetAPI.get('payment_contracts', { params })
}

const show = async ({ paymentContractId = '' }) => {
  return await dponetAPI.get(`payment_contracts/${paymentContractId}`)
}

const create = async ({ paymentContract }) => {
  return await dponetAPI.post('payment_contracts', { paymentContract })
}

const update = async ({ paymentContractId, paymentContract }) => {
  return await dponetAPI.put(`payment_contracts/${paymentContractId}`, {
    paymentContract,
  })
}

const linked = async ({ paymentContractId, contractIdentifier }) => {
  return await dponetAPI.put(`payment_contracts/${paymentContractId}/linked`, {
    contractIdentifier,
  })
}

const linkSubsidiary = async ({ paymentContractId, preRegistrationId }) => {
  return await dponetAPI.put(
    `payment_contracts/${paymentContractId}/linked_subsidiary/${preRegistrationId}`,
  )
}

const removeLinkSubsidiary = async ({
  paymentContractId,
  preRegistrationId,
}) => {
  return await dponetAPI.delete(
    `payment_contracts/${paymentContractId}/unlinked_subsidiary/${preRegistrationId}`,
  )
}

const invoices = async (data) => {
  return await dponetAPI.post(`payment_contract_invoice`, {
    paymentContractIds: data,
  })
}

const preRegistrations = async ({ paymentContractId, params }) => {
  return await dponetAPI.get(
    `payment_contracts/${paymentContractId}/pre_registrations`,
    {
      params,
    },
  )
}

const uploadCustomContract = async ({ paymentContractId, contractFile }) => {
  var formData = new FormData()

  formData.append('contract_file', contractFile)

  const config = {
    headers: {
      'Content-Type': contractFile.type,
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/payment_contracts/${paymentContractId}/upload_platform_contract`,
    data: formData,
    ...config,
  })
}

const importOrderOmie = async ({ paymentContractId }) => {
  return await dponetAPI.post(
    `payment_contracts/${paymentContractId}/import_order_omie`,
  )
}

const destroy = async ({ paymentContractId }) => {
  return await dponetAPI.delete(`payment_contracts/${paymentContractId}`)
}

const indexReajustment = async ({ paymentContractId }) => {
  return await dponetAPI.get(
    `payment_contracts/${paymentContractId}/index_reajustment`,
  )
}

const reajustmentPrice = async ({ paymentContractId, indexValue }) => {
  return await dponetAPI.put(
    `payment_contracts/${paymentContractId}/reajustment_price`,
    {
      indexValue,
    },
  )
}

const changeNextDateAdvance = async (paymentContractId) => {
  return await dponetAPI.patch(
    `payment_contracts/${paymentContractId}/change_next_date_advance`,
  )
}

const importCompany = async ({
  paymentContractId,
  file,
  sellerId,
  planId,
  companyTemplateId,
}) => {
  var data = new FormData()

  data.append('seller_id', sellerId)
  data.append('plan_id', planId)
  data.append('company_template_id', companyTemplateId)
  data.append('file', file)

  return await axios({
    method: 'POST',
    url: `${baseURL}/payment_contracts/${paymentContractId}/import_company`,
    data: data,
    headers: {
      Authorization: service.dponet.auth.getToken(),
      'Content-Type': 'multipart/form-data',
    },
  })
}

const generateCompany = async ({ paymentContractId, ...data }) => {
  return await dponetAPI.post(
    `payment_contracts/${paymentContractId}/generate_company`,
    data,
  )
}

const reports = async (params) => {
  return await dponetAPI.get('payment_contracts_report', {
    params,
  })
}

const paymentContract = {
  lists,
  show,
  create,
  linked,
  update,
  linkSubsidiary,
  removeLinkSubsidiary,
  uploadCustomContract,
  invoices,
  preRegistrations,
  importOrderOmie,
  destroy,
  indexReajustment,
  reajustmentPrice,
  changeNextDateAdvance,
  importCompany,
  generateCompany,
  reports,
}

export default paymentContract
