import helpers from 'helpers'

const mountTransfer = (data, entityId) => {
  const quantity = data.kind === 'quantity'

  return {
    entityId: entityId,
    entityType: 'Seller',
    ...data,
    rangeFrom: quantity
      ? data.rangeFrom
      : helpers.formatters.moneyToString(data.rangeFrom),
    rangeTo: quantity
      ? data.rangeTo
      : helpers.formatters.moneyToString(data.rangeTo),
  }
}

export default mountTransfer
