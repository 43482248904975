import { useState, useEffect } from 'react'
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Box,
  Typography,
  TextField,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useFetch, useSnackbar } from 'hooks'

import { BaseDialog, Label, LoadingFeedback } from 'components'

import helpers from 'helpers'
import * as service from 'service'

const ManualDialog = ({ open, setOpen, paymentContract, refresh }) => {
  const snackbar = useSnackbar()

  const [renewal, setRenewal] = useState('true')
  const [indexValue, setIndexValue] = useState('')
  const [newPrice, setNewPrice] = useState('')
  const [loading, setLoading] = useState(false)

  const { response, isLoading } = useFetch(
    service.dponet.paymentContract.indexReajustment,
    { paymentContractId: paymentContract?.id },
    [],
    open,
  )

  useEffect(() => {
    if (indexValue === '') {
      setIndexValue(response?.data?.indexValue || '')
      if (response?.data) {
        setNewPrice(
          helpers.paymentContract.calculatedNewPrice(
            paymentContract?.price,
            response?.data?.indexValue,
          ),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  const handleChange = (event) => {
    let value = helpers.formatters.onlyNumbersFloat(event.target.value)

    setNewPrice(
      helpers.paymentContract.calculatedNewPrice(paymentContract?.price, value),
    )
    setIndexValue(value)
  }

  const handleReadjustment = async () => {
    setLoading(true)
    try {
      const response = await service.dponet.paymentContract.reajustmentPrice({
        paymentContractId: paymentContract?.id,
        indexValue,
      })

      const contract = response?.data?.paymentContract

      snackbar.open({
        message: contract
          ? `A mensalidade do contrato foi reajustada para R$ ${helpers.formatters.currencyInput(
              contract.price,
            )} e a vigência foi estendida até ${helpers.formatters.date(
              contract.endDate,
            )}`
          : 'Contrato reajustado com sucesso!',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Erro ao reajustar o contrato, tente novamente mais tarde!',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      setOpen(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Reajuste de vigência e mensalidade"
      closeButtonText="Fechar"
      actionButtonText={renewal === 'true' ? 'Reajustar contrato' : ''}
      actionButton={handleReadjustment}
    >
      <LoadingFeedback loading={isLoading || loading} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Label title="Você deseja renovar esse contrato?">
            <RadioGroup
              value={renewal}
              onChange={(_, value) => setRenewal(value)}
            >
              <Box>
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Não"
                />
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Sim"
                />
              </Box>
            </RadioGroup>
          </Label>
        </Grid>
      </Grid>
      {renewal === 'true' ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Label
              title="Mensalidade atual"
              titleVariant="body1"
              description={
                'R$ ' + helpers.formatters.currencyInput(paymentContract?.price)
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Label
              title="tipo do índice"
              titleVariant="body1"
              description={paymentContract?.paymentContractRenewal?.indexType?.toUpperCase()}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Label
              title="Renovação (Nº de meses)"
              titleVariant="body1"
              description={
                paymentContract?.paymentContractRenewal?.periodOnMonth +
                ' meses'
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Label title="Índice" titleVariant="body1">
              <TextField
                value={indexValue}
                onChange={handleChange}
                placeholder="0.0"
              />
            </Label>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Label title="Nova mensalidade" titleVariant="body1">
              <TextField
                disabled
                value={helpers.formatters.currencyInput(newPrice)}
                InputProps={{
                  startAdornment: <Box mr={0.5}>R$</Box>,
                }}
              />
            </Label>
          </Grid>
        </Grid>
      ) : (
        <Alert severity="warning" color="warning">
          <Typography variant="body1">
            O contrato pode ser encerrado!
          </Typography>
          <Typography variant="body2">
            Tem 5 dias após o vencimento para renovar o contrato, caso não o
            faça, o mesmo será enviado para o fluxo de churn.
          </Typography>
        </Alert>
      )}
    </BaseDialog>
  )
}

export default ManualDialog
