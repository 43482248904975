const currencyOutput = (moneyString) => {
  if (!moneyString || typeof moneyString !== 'string') {
    return 0.0
  }

  let value = moneyString.replace('R$', '').trim()
  value = value.replace(/\./g, '')
  value = value.replace(',', '.')

  return parseFloat(value)
}

export default currencyOutput
