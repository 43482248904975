import Main from './PaymentOrdersMain'
import Show from './PaymentOrdersShow'
import Edit from './PaymentOrdersEdit'

const PaymentOrders = {
  Main,
  Show,
  Edit,
}

export default PaymentOrders
