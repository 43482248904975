import * as yup from 'yup'

const schema = yup.object().shape({
  billetExpirationDate: yup.date().required('Confirme uma data'),
  isNfe: yup.boolean(),
  nfeNumber: yup.number().when('isNfe', {
    is: true,
    then: yup.number().required('Digite o número da nota fiscal'),
  }),
  price: yup.string().when('isNfe', {
    is: true,
    then: yup.string().required('Digite o valor da mensalidade'),
  }),
})

export default schema
