import helpers from 'helpers'

const defaultValues = (kind, value = 0, plus = 0, staticFormmat = false) => {
  if (kind === 'quantity') return (parseInt(value) || 0) + plus
  if (kind === 'percentage') {
    let makeValue = plus + parseFloat(value || 0)
    if (staticFormmat) return `${makeValue}%`
    return makeValue
  }

  let floatValue = parseFloat(value) + plus

  let price = helpers.formatters.currencyInput(floatValue.toFixed(2) || '00.00')

  if (staticFormmat) return `R$ ${price}`

  return price
}

export default defaultValues
