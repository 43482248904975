import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

import { TableRow, TableCell } from 'components/Table'
import { MenuButton, Permitted } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'

import { routes } from 'Routes'

const SalesRow = ({ sale }) => {
  const history = useHistory()

  const handleEdit = () =>
    history.push(reverse(routes.sellers.edit, { sellerId: sale?.id }))

  const handleShow = () =>
    history.push(reverse(routes.sellers.show, { sellerId: sale?.id }))

  return (
    <>
      <TableRow key={sale?.id}>
        <TableCell>{sale?.id}</TableCell>
        <TableCell>{sale?.name}</TableCell>
        <TableCell>{constants.seller.TRANSLATED_KINDS[sale?.kind]}</TableCell>
        <TableCell>
          {helpers.formatters.cnpjAndCpfMask(sale?.document) || '-'}
        </TableCell>
        <TableCell>{sale?.level || '-'}</TableCell>
        <TableCell>{sale?.quantityContractActive || '-'}</TableCell>
        <TableCell>
          {helpers.formatters.currencyInput(sale?.mmr) || '-'}
        </TableCell>
        <TableCell>{sale?.status ? 'Sim' : 'Não'}</TableCell>
        <TableCell disableTooltip>
          <Permitted
            someTags={[
              constants.permissions.SELLERS.CREATE,
              constants.permissions.SELLERS.SHOW,
            ]}
          >
            <MenuButton>
              <Permitted tag={constants.permissions.SELLERS.CREATE}>
                <Button onClick={handleEdit} fullWidth size="small">
                  Editar
                </Button>
              </Permitted>
              <Permitted tag={constants.permissions.SELLERS.SHOW}>
                <Button onClick={handleShow} fullWidth size="small">
                  Visualizar
                </Button>
              </Permitted>
            </MenuButton>
          </Permitted>
        </TableCell>
      </TableRow>
    </>
  )
}

SalesRow.propTypes = {
  sale: PropTypes.object,
}

export default SalesRow
