import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, Button, Typography, Link, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Upload as UploadIcon, Download as DownloadIcon } from 'react-feather'
import { useForm } from 'react-hook-form'

import useSnackbar from 'hooks/useSnackbar'

import {
  BaseDialog,
  LoadingFeedback,
  Seller,
  CompanyTypeAndSeachInput,
  Plans,
} from 'components'

import * as service from 'service'
import helpers from 'helpers'
import { getBaseURL } from 'service/env'
import useStyles from './styles'
import schema from './schema'

const Import = ({ open, setOpen, paymentContractId, refresh }) => {
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors, watch, setValue } = useForm({
    validationSchema: schema,
    defaultValues: {
      companyTemplate: {
        id: null,
        name: '',
      },
      sellerId: '',
      segmentId: '',
      planId: null,
    },
  })

  const download = () =>
    window.open(
      `${getBaseURL('dponet')}/importacao_cliente_vendas.xlsx`,
      '_blank',
    )

  const onDrop = useCallback((acceptedFile) => {
    setFile(acceptedFile[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const { ref, ...rootProps } = getRootProps()

  const validateFile = () => {
    const validTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ]
    return file && validTypes.includes(file.type)
  }

  const onSubmit = async (data) => {
    if (!validateFile()) {
      return snackbar.open({
        message: 'Por favor selecione um documento Excel (xlsx/xls)',
        variant: 'error',
      })
    }

    setLoading(true)
    try {
      await service.dponet.paymentContract.importCompany({
        file: file,
        paymentContractId,
        sellerId: data.sellerId,
        planId: data.planId,
        companyTemplateId: data.companyTemplate.id,
      })

      refresh()

      snackbar.open({
        message:
          'Planiha importada com sucesso! em breve você receberá um e-mail com o status da importação.',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error || 'Erro ao importar empresas!',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      setOpen(false)
    }
  }

  useEffect(() => {
    if (!open) {
      setFile(null)
    }
  }, [open])

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      closeButtonText="Cancelar"
      actionButtonText="Importar"
      title="Importação de empresas (pré-cadastro)"
      formRef="id-import-company-by-contract"
      loading={loading}
    >
      <LoadingFeedback open={loading} />
      <Box className={classes.box}>
        <Alert severity="info" variant="standard">
          Para importar empresas, é necessário seguir o modelo de planilha que
          disponibilizamos. Caso não seja seguido, podem ocorrer falhas na
          importação dos dados.
        </Alert>
        <Link onClick={download}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            className={classes.buttonDownloadModel}
          >
            <DownloadIcon />
            <Typography>Baixar modelo</Typography>
          </Button>
        </Link>
      </Box>
      <Box
        {...rootProps}
        className={classes.inputImageContainer}
        mb={3}
        rootref={ref}
      >
        <input {...getInputProps()} />
        <Box m={1} mt={2}>
          <UploadIcon />
        </Box>
        <Box m={1}>
          <Typography>Arraste e solte ou selecione o documento</Typography>
        </Box>
      </Box>
      {file && (
        <Box mb={2}>
          <Typography>Arquivo:</Typography>
          <Typography variant="h5"> {file.path}</Typography>
        </Box>
      )}
      <Box mb={2}>
        <Alert severity="warning" variant="standard">
          Atenção! As informações preenchidas nos campos a seguir serão
          aplicadas a todas as empresas da planilha. Ou seja, a mesma empresa
          modelo, o mesmo executivo e o mesmo plano.
        </Alert>
      </Box>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="id-import-company-by-contract"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CompanyTypeAndSeachInput
              control={control}
              errors={errors}
              fieldName="companyTemplate"
              label="Empresa modelo"
            />
          </Grid>
          <Grid item xs={12}>
            <Seller control={control} errors={errors} />
          </Grid>
          <Plans
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
          />
        </Grid>
      </form>
    </BaseDialog>
  )
}

Import.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
  paymentContractId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Import
