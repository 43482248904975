import rangeType from './rangeType'
import mountTransfer from './mountTransfer'
import defaultValues from './defaultValues'

const seller = {
  rangeType,
  mountTransfer,
  defaultValues,
}

export default seller
