import * as yup from 'yup'

import constants from 'constants/index'

const schema = yup.object().shape({
  status: yup.string().required('O status é obrigatório'),
  price: yup.string().required('O valor é obrigatório'),
  gateway: yup.string().required('O intermediador é obrigatório'),
  paymentMethod: yup.string().required('O método de pagamento é obrigatório'),
  paidDate: yup.date().nullable(),
  withdrawalDate: yup.date().nullable(),
  orderReferenceDate: yup.date().required('A data de referência é obrigatória'),
  expirationDate: yup
    .date()
    .when('paymentMethod', {
      is: (val) => val !== constants.paymentOrder.CARD_METHOD,
      then: yup.date().required('A data de vencimento é obrigatória'),
    })
    .nullable(),
})

export default schema
