import { useEffect } from 'react'
import { Divider, Grid, TextField, Typography, Box } from '@material-ui/core'
import clsx from 'clsx'
import {
  HelpOutline as HelpIcon,
  Info as InfoIcon,
  Autorenew as AutorenewIcon,
} from '@material-ui/icons'
import { Alert } from '@material-ui/lab'

import { Controller } from 'react-hook-form'

import { Card, Select, Label } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'
import * as service from 'service'

import useStyles from './styles'
import { useSnackbar } from 'hooks'

const PaymentOption = ({
  control,
  errors,
  watch,
  setInfoOpen,
  paymentContract,
  methodPayment,
  mode,
  initialStatus,
  setValue,
  refresh,
}) => {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const disabled = mode === 'show'
  const displayed = mode !== 'new'
  const isEdit = mode === 'edit'

  const gateway = watch('gateway')

  const isBankTransfer = methodPayment === 'bank_transfer'
  const isSuperfin = gateway === 'superfin'
  const isOmie = gateway === 'omie'
  const isCreditCard = methodPayment === 'card'
  const isPix = methodPayment === 'pix'

  const fixedOptionGateway = isBankTransfer || isCreditCard || isPix
  const nextAdvanceDisplay = paymentContract?.havePaymentOrders

  useEffect(() => {
    if (isBankTransfer && isSuperfin) setValue('gateway', 'omie')
    if (isPix && isOmie) setValue('gateway', 'superfin')
    if (isCreditCard && isOmie) setValue('gateway', 'superfin')
    if (isCreditCard) {
      setValue('typeInvoice', 'monthly')
      if (
        ['initial', 'manual'].includes(watch('kind')) &&
        paymentContract?.codeContractOmie
      ) {
        setValue('kind', 'recurrence')
      }
    }
    if (!isCreditCard) {
      setValue('kind', paymentContract?.kind)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methodPayment, gateway, initialStatus])

  const kinds = () => {
    const kinded =
      constants.preRegistration.OPTIONS_KIND_PAYMENT_FEATURES_BY_METHOD_PAY[
        methodPayment
      ] || []

    if (paymentContract?.codeContractOmie) {
      return kinded.filter((kind) => kind.id !== 'initial')
    }

    return kinded
  }

  const dpoServiceHelper = () => {
    const message = helpers.paymentContract.statusDpoService(
      paymentContract?.paymentDpoService,
    )

    setInfoOpen({
      open: true,
      text: message,
      title: 'Mensalidade',
    })
  }

  const changeNextDateAdvance = async () => {
    try {
      await service.dponet.paymentContract.changeNextDateAdvance(
        paymentContract?.id,
      )
      refresh()

      snackbar.open({
        message: 'Data do faturamento atualizada com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.message ||
          'Erro ao atualizar a data de faturamento, tente novamente mais tarde!',
        variant: 'error',
      })
    }
  }

  return (
    <Card title="Opções do pagamento">
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Controller
            as={
              <Select
                disabled={disabled || isCreditCard}
                label="Tipo de faturamento"
                items={constants.preRegistration.INVOICE_OPTIONS || []}
              />
            }
            control={control}
            name="typeInvoice"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            control={control}
            name="price"
            mode="onChange"
            as={
              <TextField
                disabled={disabled}
                label="Valor da contratação"
                fullWidth
                variant="outlined"
                error={!!errors?.price}
                helperText={errors?.price?.message}
                InputProps={{
                  startAdornment: (
                    <Box className={classes.iconBtn} onClick={dpoServiceHelper}>
                      <InfoIcon color="primary" size={10} />
                    </Box>
                  ),
                }}
              />
            }
            onChange={([event]) => {
              return helpers.formatters.currencyInput(event.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            as={
              <Select
                disabled={disabled}
                label="Status da cobrança"
                items={kinds()}
                startIcon={<InfoIcon color="primary" size={10} />}
                actionStartIcon={() =>
                  setInfoOpen({
                    open: true,
                    text: constants.preRegistration.KIND_DESCRIPTION[
                      watch('kind')
                    ],
                    title:
                      constants.preRegistration.KIND_TRANSLATED[watch('kind')],
                  })
                }
              />
            }
            control={control}
            name="kind"
            mode="onChange"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            as={
              <Select
                label="Intermediador de cobrança (gateway)"
                items={
                  constants.preRegistration.OPTIONS_GATEWAY_PAYMENT_FEATURES
                }
                disabled={disabled || fixedOptionGateway}
                startIcon={<HelpIcon color="primary" size={10} />}
                actionStartIcon={() =>
                  setInfoOpen({
                    open: true,
                    text: constants.preRegistration.GATEWAY_DESCRIPTION[
                      watch('gateway')
                    ],
                    title:
                      constants.preRegistration.GATEWAY_TRANSLATED[
                        watch('gateway')
                      ],
                  })
                }
                InputProps={{
                  endAdornment: (
                    <Box mr={2}>
                      <Typography variant="body2" color="textSecondary">
                        {isSuperfin ? 'Santander' : 'Bradesco'}
                      </Typography>
                    </Box>
                  ),
                }}
              />
            }
            control={control}
            name="gateway"
            mode="onChange"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            disabled={disabled || isCreditCard}
            control={control}
            name="daysAdvance"
            mode="onChange"
            type="number"
            as={
              <TextField
                label="Dia de emissão (faturação)"
                fullWidth
                variant="outlined"
                error={!!errors?.daysAdvance}
                helperText={errors?.daysAdvance?.message}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            as={
              <TextField
                disabled={disabled}
                type="number"
                label="Dia fixo de Vencimento"
                color="primary"
                variant="outlined"
                error={!!errors.billingDay}
                helperText={!!errors.billingDay?.message}
                fullWidth
                className={clsx({
                  [classes.hidden]:
                    watch('typeBillingDay') ===
                      constants.paymentOrder.TYPE_BILLING_DAY_DYNAMIC ||
                    isCreditCard,
                })}
              />
            }
            control={control}
            name="billingDay"
            mode="onChange"
          />
          <Controller
            as={
              <TextField
                disabled={disabled}
                type="number"
                label="Numero de dias após a emissão"
                color="primary"
                variant="outlined"
                error={!!errors.billingDayDynamic}
                helperText={errors.billingDayDynamic?.message}
                fullWidth
                className={clsx({
                  [classes.hidden]:
                    watch('typeBillingDay') ===
                      constants.paymentOrder.TYPE_BILLING_DAY_FIXED ||
                    isCreditCard,
                })}
              />
            }
            control={control}
            name="billingDayDynamic"
            mode="onChange"
          />
        </Grid>
        {displayed && nextAdvanceDisplay && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {isEdit && (
              <Grid item xs={12}>
                <Alert severity="info">
                  <Typography variant="body1">
                    Os prazos de faturamento e vencimento são calculados
                    automaticamente e serão atualizados apenas quando o contrato
                    for salvo.
                  </Typography>
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Label
                title="Data do próximo faturamento"
                tooltip={false}
                description={
                  <Box display="flex">
                    <Typography variant="caption" color="textSecondary">
                      {helpers.formatters.date(
                        paymentContract?.nextDateAdvance,
                      )}
                    </Typography>
                    <Box
                      ml={2}
                      display="flex"
                      alignItems="center"
                      className={classes.iconBtnByRefresh}
                    >
                      <AutorenewIcon
                        size={14}
                        onClick={changeNextDateAdvance}
                      />
                      <Typography variant="caption" color="textSecondary">
                        Atualizar
                      </Typography>
                    </Box>
                  </Box>
                }
              />
            </Grid>
            {!isCreditCard && (
              <Grid item xs={12}>
                <Label
                  title="Data do próximo vencimento"
                  tooltip={false}
                  description={helpers.formatters.date(
                    paymentContract?.nextBillingDate,
                  )}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Card>
  )
}

export default PaymentOption
