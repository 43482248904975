import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { Controller } from 'react-hook-form'

import {
  Card,
  Label,
  LoadingFeedback,
  Select as SelectComponent,
} from 'components'

import useFetch from 'hooks/useFetch'

import useStyles from '../styles'
import * as service from 'service'
import constants from 'constants/index'

const GeneralInformation = ({ isEdit, coupon, formMethods }) => {
  const { errors, control, register, watch } = formMethods
  const classes = useStyles()
  const couponPresence = isEdit ? coupon?.status : true
  const [checked, setChecked] = useState(couponPresence)
  const [expirationMinDate, setExpirationMinDate] = useState(new Date())

  const labelChecked = checked ? 'Ativo' : 'Inativo'

  const startDate = watch('startDate')

  const { response, isLoading } = useFetch(
    service.dponet.sellers.index,
    {
      kindNotIn: [constants.seller.PARTNER_KIND],
      perPage: 1000,
    },
    [],
  )
  useEffect(() => {
    const currentDay = new Date()

    if (startDate) {
      setExpirationMinDate(startDate)
      if (startDate > currentDay) {
        setChecked(false)
      } else {
        setChecked(true)
      }
    } else {
      setExpirationMinDate(new Date())
    }
    //eslint-disable-next
  }, [startDate])

  return (
    <Box display="block">
      <Box mb={2}>
        <Typography variant="h4">Informações gerais</Typography>
      </Box>
      <LoadingFeedback open={isLoading} />
      {!isLoading && (
        <Card title="Dados básicos" paddingCard={5}>
          <Grid container className={classes.gapGrid}>
            <Grid item xs={12}>
              <Label
                padBuTitle={0}
                titleVariant="body1"
                title="Status do cupom"
                marginTopChildren={0}
              >
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    className={classes.labelSwitch}
                    control={
                      <Switch
                        checked={checked}
                        onChange={(event) => {
                          setChecked(event.target.checked)
                        }}
                      />
                    }
                    inputRef={register}
                    name="status"
                  />
                  <Typography variant="body1">{labelChecked}</Typography>
                </Box>
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Código"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.code}
                    helperText={errors?.code?.message}
                    fullWidth
                    disabled={isEdit}
                  />
                }
                control={control}
                name="code"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Nome"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    fullWidth
                  />
                }
                control={control}
                name="name"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <SelectComponent
                    items={response?.data?.sellers || []}
                    anotherOption
                    label="Informe o representante comercial"
                    error={!!errors.sellerId}
                    helperText={errors?.sellerId?.message}
                  />
                }
                control={control}
                name="sellerId"
                mode="onBlur"
              />
            </Grid>
            <Box width="100%">
              <Label
                padBuTitle={1}
                titleVariant="body1"
                title="Validade"
                marginTopChildren={0}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <Controller
                    as={
                      <DatePicker
                        label="Início"
                        color="primary"
                        format="DD/MM/yyyy"
                        variant="outlined"
                        error={!!errors.startDate}
                        helperText={errors?.startDate?.message}
                        fullWidth
                        disablePast
                        clearable
                      />
                    }
                    control={control}
                    name="startDate"
                    mode="onBlur"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <Controller
                    as={
                      <DatePicker
                        label="Fim"
                        color="primary"
                        format="DD/MM/yyyy"
                        variant="outlined"
                        error={!!errors.expirationDate}
                        helperText={errors?.expirationDate?.message}
                        minDate={expirationMinDate}
                        fullWidth
                        clearable
                      />
                    }
                    control={control}
                    name="expirationDate"
                    mode="onBlur"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Card>
      )}
    </Box>
  )
}

GeneralInformation.propTypes = {
  isEdit: PropTypes.bool,
  coupon: PropTypes.object,
  formMethods: PropTypes.object.isRequired,
}

GeneralInformation.defaultProps = {
  isEdit: false,
}

export default GeneralInformation
