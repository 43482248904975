import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Box, Grid, Paper } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'

import { useFetch, useSnackbar } from 'hooks'

import { BaseDialog, LoadingFeedback } from 'components'

import * as service from 'service'
import helpers from 'helpers'

const GenerateCompany = ({ open, setOpen, paymentContractId, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [preRegistrationIds, setPreRegistrationIds] = useState([])

  const snackbar = useSnackbar()

  const { response, isLoading } = useFetch(
    service.dponet.paymentContract.preRegistrations,
    {
      paymentContractId: paymentContractId,
      params: { companyIdNull: true, perPage: 1000 },
    },
    [],
  )

  const { columns, rows } = helpers.paymentContract.companyTableGrid(
    response?.data?.preRegistrations || [],
  )

  const handleGenerate = async () => {
    if (isEmpty(preRegistrationIds))
      return snackbar.open({
        message: 'Selecione ao menos uma empresa!',
        variant: 'error',
      })

    setLoading(true)
    try {
      await service.dponet.paymentContract.generateCompany({
        paymentContractId,
        preRegistrationIds,
      })

      refresh()

      snackbar.open({
        message:
          'Agendamento da geração das empresas feita com sucesso! em breve você receberá um e-mail com o status.',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error || 'Erro ao gerrar as empresas!',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      setOpen(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      closeButtonText="Cancelar"
      actionButtonText="Gerar"
      title="Gerar de empresas (pré-cadastro)"
      loading={isLoading || loading}
      actionButton={handleGenerate}
      dialogSize="lg"
    >
      <LoadingFeedback open={isLoading || loading} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Box height="500px" width="100%">
              <DataGrid
                rows={rows}
                columns={columns}
                checkboxSelection
                disableSelectionOnClick
                disableColumnFilter
                hideFooterPagination
                onSelectionModelChange={(ids) => setPreRegistrationIds(ids)}
                localeText={{
                  noRowsLabel: 'Nenhum encontrado',
                  columnMenuUnsort: 'Desfazer ordenação',
                  columnMenuSortAsc: 'Ordenar de forma ascendente',
                  columnMenuSortDesc: 'Ordenar de forma descendente',
                  columnMenuHideColumn: 'Ocultar coluna',
                  columnMenuShowColumns: 'Mostrar colunas',
                }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </BaseDialog>
  )
}

GenerateCompany.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
  paymentContractId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default GenerateCompany
