import permissions from '../../permissions'

const SELLER_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.SELLERS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.SELLERS.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.SHOW,
    tag: permissions.SELLERS.SHOW,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE + ' comissões',
    tag: permissions.SELLERS.MANAGE_COMMISSION,
  },
]

export default SELLER_LIST
