import { Fragment, useState } from 'react'
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Box,
  Hidden,
} from '@material-ui/core'
import { Delete as DeleteIcon, Save as SaveIcon } from '@material-ui/icons'

import { useSnackbar } from 'hooks'

import { EditCommisionDialog } from '../'

import helpers from 'helpers'
import useStyles from '../../styles'

import * as service from 'service'

const ListCommision = ({ commisions, refresh, refreshSeller, disabled }) => {
  const snackbar = useSnackbar()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [data, setData] = useState({})

  const handleRemove = async (commisionId) => {
    setLoading(true)
    try {
      await service.dponet.transferCommisions.destroy({
        transferCommisionId: commisionId,
      })
      snackbar.open({
        message: 'Comissão removida com sucesso!',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Ocorreu um erro ao remover a comissão',
        ),
        variant: 'error',
      })
    } finally {
      refreshSeller()
      setLoading(false)
    }
  }

  const handleOpenEdit = (commision) => {
    setOpenEdit(true)
    setData(commision)
  }

  return (
    <Grid container spacing={2}>
      {commisions?.map((commision) => (
        <Fragment key={commision?.id}>
          <Grid item xs={3}>
            <TextField
              InputProps={{
                startAdornment: <Box mr={1}>Nível</Box>,
              }}
              type="text"
              defaultValue={commision?.level}
              label="Nível"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="text"
              defaultValue={helpers.sellers.defaultValues(
                commision?.kind,
                commision?.rangeFrom,
              )}
              label="De"
              fullWidth
              variant="outlined"
              disabled
              InputProps={{
                startAdornment: (
                  <Box mr={1}>{commision?.kind === 'quantity' ? '' : 'R$'}</Box>
                ),
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="text"
              defaultValue={helpers.sellers.defaultValues(
                commision?.kind,
                commision?.rangeTo,
              )}
              label="Até"
              fullWidth
              variant="outlined"
              disabled
              InputProps={{
                startAdornment: (
                  <Box mr={1}>{commision.kind === 'quantity' ? '' : 'R$'}</Box>
                ),
              }}
            />
          </Grid>
          <Grid item xs={disabled ? 3 : 1}>
            <TextField
              type="text"
              defaultValue={commision?.percentage}
              label="Comissão (%)"
              fullWidth
              variant="outlined"
              disabled
              InputProps={{
                endAdornment: <Box mr={1}>%</Box>,
              }}
            />
          </Grid>
          {!disabled && (
            <>
              <Grid item xs={1}>
                <Button
                  fullWidth
                  className={classes.btn}
                  startIcon={
                    loading ? (
                      <CircularProgress color="white" size={20} />
                    ) : (
                      <SaveIcon />
                    )
                  }
                  onClick={() => handleOpenEdit(commision)}
                  variant="contained"
                  color="secondary"
                  type="button"
                  disabled={loading}
                >
                  <Hidden mdDown>Editar</Hidden>
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  fullWidth
                  className={classes.removeBtn}
                  startIcon={
                    loading ? (
                      <CircularProgress color="white" size={20} />
                    ) : (
                      <DeleteIcon />
                    )
                  }
                  onClick={() => handleRemove(commision?.id)}
                  variant="contained"
                  type="button"
                  disabled={loading}
                >
                  <Hidden mdDown>Remover</Hidden>
                </Button>
              </Grid>
            </>
          )}
          {openEdit && (
            <EditCommisionDialog
              open={openEdit}
              commision={data}
              setOpen={setOpenEdit}
              refresh={refresh}
              refreshSeller={refreshSeller}
            />
          )}
        </Fragment>
      ))}
    </Grid>
  )
}

export default ListCommision
