import { useState, useEffect } from 'react'
import {
  Grid,
  TextField,
  Button,
  Tooltip,
  InputAdornment,
} from '@material-ui/core'
import {
  AlertCircle as AlertCircleIcon,
  Check as CheckIcon,
} from 'react-feather'
import { Controller } from 'react-hook-form'

import { Card, Label, Select } from 'components'
import { Linked } from './components'

import helpers from 'helpers'

import constants from 'constants/index'

const Contract = ({
  control,
  errors,
  disabled,
  codeContractOmie,
  paymentContract,
  refresh,
  setValue,
  watch,
}) => {
  const [linkedOpener, setLinkedOpener] = useState(false)

  const handleOpenLinked = () => setLinkedOpener(true)

  const {
    CONSULTIVE_SALES_LABEL,
    PUBLIC_AGENCY_LABEL,
    VERTICAL_OPTIONS,
    TYPE_SERVICES_OPTIONS,
    SERVICES_LABEL,
  } = constants.paymentContract

  const isConsultiveSales = watch('typeService') === CONSULTIVE_SALES_LABEL
  const isPublicAgency = watch('vertical') === PUBLIC_AGENCY_LABEL
  const verticalOptions = VERTICAL_OPTIONS[watch('typeService')] || []

  useEffect(() => {
    if (isConsultiveSales && !isPublicAgency) {
      setValue('vertical', PUBLIC_AGENCY_LABEL)
    }

    if (!isConsultiveSales && isPublicAgency) {
      setValue('vertical', SERVICES_LABEL)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('typeService')])

  return (
    <>
      <Card
        title="Informações do contrato"
        actionButton={
          <>
            {!codeContractOmie && disabled && (
              <Button
                onClick={handleOpenLinked}
                size="small"
                variant="contained"
                color="primary"
              >
                Vincular contrato do omie
              </Button>
            )}
          </>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              disabled={disabled}
              control={control}
              name="contractIdentifier"
              mode="onBlur"
              type="text"
              as={
                <TextField
                  label="Número do contrato"
                  fullWidth
                  variant="outlined"
                  error={!!errors?.contractIdentifier}
                  helperText={errors?.contractIdentifier?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tooltip
                          title={
                            codeContractOmie
                              ? 'Contrato vinculado ao OMIE'
                              : 'Contrato não vinculado ao OMIE'
                          }
                          placement="top"
                        >
                          {codeContractOmie ? (
                            <CheckIcon color="green" />
                          ) : (
                            <AlertCircleIcon color="red" />
                          )}
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              disabled={disabled}
              control={control}
              name="typeService"
              mode="onChange"
              type="text"
              as={
                <Select
                  label="Tipo de Serviço"
                  items={TYPE_SERVICES_OPTIONS}
                  fullWidth
                  error={!!errors?.typeService}
                  helperText={errors?.typeService?.message}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              disabled={disabled || isConsultiveSales}
              control={control}
              name="vertical"
              mode="onChange"
              type="text"
              as={
                <Select
                  label="Vertical"
                  items={verticalOptions}
                  fullWidth
                  error={!!errors?.vertical}
                  helperText={errors?.vertical?.message}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label
              title="Data de inicio do contrato"
              description={
                paymentContract?.codeContractOmie
                  ? helpers.formatters.date(paymentContract?.startDate || '-')
                  : 'Disponível após vinculação com o OMIE'
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label
              title="Data de final do contrato"
              description={
                paymentContract?.codeContractOmie
                  ? helpers.formatters.date(paymentContract?.endDate || '-')
                  : 'Disponível após vinculação com o OMIE'
              }
            />
          </Grid>
        </Grid>
      </Card>
      {linkedOpener && (
        <Linked
          open={linkedOpener}
          setOpen={setLinkedOpener}
          paymentContractId={paymentContract?.id}
          refresh={refresh}
        />
      )}
    </>
  )
}

export default Contract
