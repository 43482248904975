import Main from './Main'
import New from './New'
import Edit from './Edit'
import Show from './Show'

const Sellers = {
  Main,
  New,
  Edit,
  Show,
}

export default Sellers
