import PropTypes from 'prop-types'
import { useState } from 'react'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Plus as PlusIcon } from 'react-feather'
import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'

import { useFetch, usePagination } from 'hooks'

import {
  LinkedDialog,
  PreRegistrationsTable,
  Import,
  GenerateCompany,
} from './components'
import { ButtonComponent, LoadingFeedback } from 'components'
import constants from 'constants/index'

import { routes } from 'Routes'
import * as service from 'service'

const PreRegistrationsCard = ({ paymentContract, contractRefresh, mode }) => {
  const history = useHistory()
  const theme = useTheme()
  const [openNew, setOpenNew] = useState(false)
  const [openImport, setOpenImport] = useState(false)
  const [openGenerateCompany, setOpenGenerateCompany] = useState(false)

  const handleCreateUser = () => {
    history.push(routes.preRegistrations.new, {
      type: constants.preRegistration.TYPE_REAL,
      typeClient: constants.preRegistration.CLIENT_TYPE,
      parentContractId: paymentContract?.id,
    })
  }

  const handleRedirect = (tab) => {
    if (mode === 'edit') contractRefresh()

    const action = mode === 'show' ? 'show' : 'edit'

    return history.push(
      reverse(routes.contracts[action], {
        contractId: paymentContract?.id,
      }),
      { tab },
    )
  }

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const handleLInked = () => setOpenNew(true)
  const handleImport = () => setOpenImport(true)
  const handleGenerateCompany = () => setOpenGenerateCompany(true)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.paymentContract.preRegistrations,
    {
      paymentContractId: paymentContract?.id,
      params: { perPage, page },
    },
    [page, perPage],
  )

  const preRegistrations = response?.data?.preRegistrations || []

  return (
    <>
      <Card>
        <LoadingFeedback open={isLoading} />
        <Box width="100%">
          <Grid container>
            <Grid item xs={6}>
              <Box
                width="100%"
                p={2}
                height="100%"
                display="flex"
                alignItems="center"
              >
                <Typography variant="h5">Empresas (pré-cadastro)</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Box mr={2}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleGenerateCompany}
                  >
                    Gerar empresa (DPOnet)
                  </Button>
                </Box>
                <Box mr={2}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleImport}
                  >
                    Importar
                  </Button>
                </Box>
                <Box mr={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLInked}
                  >
                    Vincular
                  </Button>
                </Box>
                <Button
                  startIcon={<PlusIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={handleCreateUser}
                >
                  Criar
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />
        </Box>
        <PreRegistrationsTable
          paymentContractId={paymentContract?.id}
          preRegistrationPrincipalId={paymentContract?.preRegistration?.id}
          preRegistrations={preRegistrations}
          refresh={refresh}
        />
        <Box px={2} display="flex" justifyContent="flex-end">
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={isDesktop ? 'Por página' : ''}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </Box>
      </Card>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Box mr={2}>
          <ButtonComponent
            variant="outlined"
            onClick={() => handleRedirect('contract')}
            type="button"
            title="Voltar"
          />
        </Box>
        <ButtonComponent
          onClick={() => handleRedirect('payment')}
          type="button"
          title="Próximo"
        />
      </Box>
      {openNew && (
        <LinkedDialog
          paymentContractId={paymentContract?.id}
          preRegistrationIds={paymentContract?.preRegistrationIds}
          refresh={refresh}
          openNew={openNew}
          setOpenNew={setOpenNew}
        />
      )}
      {openImport && (
        <Import
          setOpen={setOpenImport}
          open={openImport}
          paymentContractId={paymentContract?.id}
          refresh={refresh}
        />
      )}
      ,
      {openGenerateCompany && (
        <GenerateCompany
          setOpen={setOpenGenerateCompany}
          open={openGenerateCompany}
          paymentContractId={paymentContract?.id}
          refresh={refresh}
        />
      )}
    </>
  )
}

PreRegistrationsCard.propTypes = {
  paymentContract: PropTypes.object,
  mode: PropTypes.string,
  contractRefresh: PropTypes.func,
}

export default PreRegistrationsCard
