import { useState } from 'react'
import PropTypes from 'prop-types'

import useSnackbar from 'hooks/useSnackbar'

import { ConfirmationDialog, LoadingFeedback } from 'components'

import * as service from 'service'
import helpers from 'helpers'

const ExportDialog = ({ open, setOpen, params }) => {
  const [isLoading, setIsloading] = useState(false)
  const snackbar = useSnackbar()

  const handleExport = async () => {
    setIsloading(true)
    try {
      await service.dponet.paymentContract.reports(params)

      snackbar.open({
        message:
          'Relatório foi gerado com sucesso e enviado para o e-mail do usuário logado!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Falha na geração do relatório!',
        variant: 'error',
      })
    } finally {
      setOpen(false)
      setIsloading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <ConfirmationDialog
        open={open}
        setOpen={setOpen}
        message="Deseja exportar os contratos?"
        subMessage="A exportação considera os filtros aplicados."
        alert="A exportação pode levar alguns minutos, dependendo da quantidade de contratos, e será enviada para o e-mail do usuário logado."
        alertColor="secondary"
        buttonText="Exportar"
        actionAcceptButton={handleExport}
      />
    </>
  )
}

ExportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  params: PropTypes.object,
}

export default ExportDialog
