import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  cardText: {
    textTransform: 'uppercase',
  },
  tab: {
    color: '#CCCCCC',
    cursor: 'pointer',
    width: 200,
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#EEE',
    },
  },
  tabActive: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.white,
    borderRadius: 4,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.white,
    },
  },
}))

export default styles
