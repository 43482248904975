import { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'

import { useFetch, useSnackbar, usePermissions } from 'hooks'

import { Card, LoadingFeedback } from 'components'
import { AddCommision, ListCommision, Kinded } from './components'

import { useForm } from 'react-hook-form'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'
import { Alert, AlertTitle } from '@material-ui/lab'

const TransferCommision = ({ seller, refreshSeller, disabled }) => {
  const permissions = usePermissions()
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.transferCommisions.index,
    {
      entityId: seller?.id,
      entityType: 'Seller',
      order: 'level desc, id desc',
    },
    [],
  )

  const permitted = permissions.permitted(
    constants.permissions.SELLERS.MANAGE_COMMISSION,
  )

  const PermittedChange = permitted && !disabled
  const displayAlert = !permitted && !disabled

  const lastCommision = seller?.lastTransferCommision
  const rangeTo = lastCommision?.rangeTo || '0'
  const percentage = lastCommision?.percentage ?? 10

  const { handleSubmit, control, errors, watch, reset } = useForm({
    defaultValues: {
      kind: lastCommision?.kind || 'quantity',
      level: lastCommision ? lastCommision?.level + 1 : 1,
      rangeFrom: lastCommision
        ? helpers.sellers.defaultValues(lastCommision?.kind, rangeTo, 1, false)
        : 0,
      rangeTo: lastCommision
        ? helpers.sellers.defaultValues(lastCommision?.kind, rangeTo, 2, false)
        : 0,
      percentage: lastCommision
        ? helpers.sellers.defaultValues('percentage', percentage, 0.1, false)
        : 10,
    },
  })

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await service.dponet.transferCommisions.create(
        helpers.sellers.mountTransfer(data, seller?.id),
      )
      snackbar.open({
        message: 'Comissão adicionada com sucesso!',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Ocorreu um erro ao adicionar a comissão',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      refreshSeller()
      reset()
    }
  }

  return (
    <Card title="Opções de níveis">
      <LoadingFeedback isLoading={isLoading || loading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {displayAlert && (
            <Grid xs={12} item>
              <Alert variant="outlined" severity="warning">
                <AlertTitle>
                  Você não tem permissão para criar, editar ou excluir
                  comissões.
                </AlertTitle>
                <Typography variant="span">
                  Entre em contato com o administrador ou um colaborador com
                  acesso à configuração dos perfis de usuário.
                </Typography>
              </Alert>
            </Grid>
          )}
          {PermittedChange && (
            <>
              <Grid xs={12} item>
                <Kinded control={control} disabled={disabled} />
              </Grid>
              <Grid xs={12} item>
                <Typography variant="h6">
                  Selecionar a faixa do nível por quantidade de clientes
                  (CNPJ/CPF) ou MMR.
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <AddCommision
                  control={control}
                  errors={errors}
                  watch={watch}
                  loading={loading}
                  disabled={disabled}
                />
              </Grid>
            </>
          )}
          <Grid xs={12} item>
            <ListCommision
              commisions={response?.data?.transferCommisions || []}
              refresh={refresh}
              refreshSeller={refreshSeller}
              disabled={!PermittedChange}
            />
          </Grid>
        </Grid>
      </form>
    </Card>
  )
}

export default TransferCommision
