import { useState, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import AuthContext from 'contexts/AuthContext'

import {
  AlertOctagon as AlertOctagonIcon,
  Archive as ArchiveIcon,
  BarChart as BarChartIcon,
  Book as BookIcon,
  Briefcase as BriefcaseIcon,
  DollarSign as DollarIcon,
  Layers as LayersIcon,
  Settings as SettingsIcon,
  User as UserIcon,
  Users as UsersIcon,
  Upload as UploadIcon,
  HelpCircle as HelpIcon,
  Feather as FeatherIcon,
  Code as CodeIcon,
} from 'react-feather'

import * as service from 'service'
import permissionsTags from 'constants/permissions'

import helpers from 'helpers'
import { routes } from 'Routes'
import constants from 'constants/index'

const AuthProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [permissions, setPermissions] = useState(null)
  const [menuItems, setMenuItems] = useState([])
  const [auth, setAuth] = useState(false)
  const [homePage, setHomePage] = useState()

  const queryClient = useQueryClient()

  const loadData = async () => {
    const responseMe = await service.dponet.user.me()
    const responsePermissions = await service.dponet.user.permissions()

    const permissions = responsePermissions?.data?.profilePermissions
    const user = responseMe?.data?.user

    setUser(user)
    setPermissions(permissions)

    if (user?.profile?.id === constants.user.ADMIN_PROFILE_ID) {
      setHomePage(routes.dataProcess.all)
    } else {
      loadHomePage(permissions)
    }

    const menuItems = [
      {
        somePermissions: [
          permissionsTags.COMPANIES.LIST,
          permissionsTags.DEPARTMENT.LIST,
          permissionsTags.QUESTIONNAIRE_BY_COMPANY.LIST,
          permissionsTags.DATA_PROCESSES.LIST,
          permissionsTags.COMPANY_SUPPLIERS.LIST,
        ],
        items: [
          {
            main: true,
            title: 'Empresas',
            icon: BriefcaseIcon,
            items: [
              {
                title: 'Empresas',
                permission: permissionsTags.COMPANIES.LIST,
                href: '/companies',
              },
              {
                title: 'Departamentos',
                permission: permissionsTags.DEPARTMENT.LIST,
                href: routes.departments.all,
              },
              {
                title: 'Questionários',
                permission: permissionsTags.QUESTIONNAIRE_BY_COMPANY.LIST,
                href: '/questionnaires',
              },
              {
                title: 'Auditoria de Processos',
                permission: permissionsTags.DATA_PROCESSES.LIST,
                href: '/company-audits',
              },
              {
                title: 'Fornecedores',
                permission: permissionsTags.COMPANY_SUPPLIERS.LIST,
                href: routes.suppliers.all,
              },
              {
                title: 'DPO Easy',
                permission: permissionsTags.COMPANIES.LIST,
                href: '/companies/lite',
              },
            ],
          },
        ],
      },
      {
        items: [
          {
            main: true,
            title: 'Processos',
            permission: permissionsTags.DATA_PROCESSES.LIST,
            icon: LayersIcon,
            href: '/data-processes/',
          },
          {
            main: true,
            title: 'Enquadramento Legal',
            somePermissions: [
              permissionsTags.DATA_PROCESSES.REVIEW,
              permissionsTags.DATA_PROCESSES.MANAGE_LEGAL_FRAMEWORKS,
            ],
            icon: BookIcon,
            href: routes.legalFrameworks,
          },
          {
            main: true,
            title: 'Incidentes',
            permission: permissionsTags.INCIDENTS.LIST,
            icon: AlertOctagonIcon,
            href: '/incidents',
          },
        ],
      },
      {
        somePermissions: [
          permissionsTags.USERS.LIST,
          permissionsTags.COLLABORATORS.LIST,
        ],
        items: [
          {
            main: true,
            title: 'Pessoas',
            icon: UsersIcon,
            items: [
              {
                title: 'Usuários',
                permission: permissionsTags.USERS.LIST,
                href: '/users',
              },
              {
                title: 'Colaboradores',
                permission: permissionsTags.COLLABORATORS.LIST,
                href: '/collaborators',
              },
            ],
          },
        ],
      },
      {
        somePermissions: [
          permissionsTags.CLIENT_PROFILES.LIST,
          permissionsTags.COLLABORATOR_PROFILES.LIST,
        ],
        items: [
          {
            main: true,
            title: 'Perfis',
            icon: UserIcon,
            items: [
              {
                title: 'Perfis de usuário',
                permission: permissionsTags.CLIENT_PROFILES.LIST,
                href: '/user-profiles',
              },
              {
                title: 'Perfis de colaborador',
                permission: permissionsTags.COLLABORATOR_PROFILES.LIST,
                href: '/collaborator-profiles',
              },
            ],
          },
        ],
      },
      {
        somePermissions: [
          permissionsTags.PLANS.LIST,
          permissionsTags.COUPONS.LIST,
          permissionsTags.PRE_REGISTRATIONS.LIST,
          permissionsTags.PAYMENT_CONTRACTS.LIST,
          permissionsTags.PAYMENT_ORDERS.LIST,
          permissionsTags.SELLERS.LIST,
          permissionsTags.CHURNS.LIST,
        ],
        items: [
          {
            main: true,
            title: 'Vendas',
            icon: DollarIcon,
            items: [
              {
                title: 'Clientes',
                somePermissions: [permissionsTags.PRE_REGISTRATIONS.LIST],
                href: routes.preRegistrations.all,
              },
              {
                title: 'Contratos',
                somePermissions: [permissionsTags.PAYMENT_CONTRACTS.LIST],
                href: routes.contracts.all,
              },
              {
                title: 'Ordens de pagamentos',
                somePermissions: [permissionsTags.PAYMENT_ORDERS.LIST],
                href: routes.paymentOrders.all,
              },
              {
                title: 'Vendedor & Parceiro',
                href: routes.sellers.all,
                somePermissions: [permissionsTags.SELLERS.LIST],
              },
              {
                title: 'Sublicenciamentos',
                somePermissions: [permissionsTags.PRE_REGISTRATIONS.LIST],
                href: routes.sublicensings.all,
              },
              {
                title: 'Planos',
                somePermissions: [permissionsTags.PLANS.LIST],
                href: routes.plans.all,
              },
              {
                title: 'Cupons',
                permission: permissionsTags.COUPONS.LIST,
                href: routes.coupons.all,
              },
              {
                title: 'Faturamentos',
                somePermissions: [permissionsTags.PAYMENT_CONTRACTS.INVOICE],
                href: routes.contractInvoices.all,
              },
              {
                title: 'Churns',
                somePermissions: [permissionsTags.CHURNS.LIST],
                href: routes.churns.all,
              },
              {
                title: 'Dashboard financeiro',
                href: routes.financialDashboard.all,
              },
            ],
          },
        ],
      },
      {
        items: [
          {
            somePermissions: [
              permissionsTags.SUBGROUPS.LIST,
              permissionsTags.GROUPS.LIST,
              permissionsTags.SEGMENTS.LIST,
              permissionsTags.ECONOMIC_ACTIVITIES.LIST,
              permissionsTags.COMPANY_SIZES.LIST,
            ],
            main: true,
            title: 'Segmentação',
            icon: ArchiveIcon,
            items: [
              {
                title: 'Grupos',
                href: '/groups',
                permission: permissionsTags.GROUPS.LIST,
              },
              {
                title: 'Subgrupos',
                href: '/subgroups',
                permission: permissionsTags.SUBGROUPS.LIST,
              },
              {
                title: 'Segmentos',
                href: '/segments',
                permission: permissionsTags.SEGMENTS.LIST,
              },
              {
                title: 'Atividades econômicas',
                href: '/economic-activities',
                permission: permissionsTags.ECONOMIC_ACTIVITIES.LIST,
              },
              {
                title: 'Tamanho de empresas',
                href: '/company-sizes',
                permission: permissionsTags.COMPANY_SIZES.LIST,
              },
            ],
          },
        ],
      },
      {
        items: [
          {
            somePermissions: [
              permissionsTags.REPORTS.ACTIVATION,
              permissionsTags.REPORTS.COMPANY_QUERY,
              permissionsTags.REPORTS.SEAL,
              permissionsTags.REPORTS.TICKET,
            ],
            main: true,
            title: 'Relatórios',
            icon: BarChartIcon,
            items: [
              {
                title: 'Ativação',
                href: '/reports/activation',
                permission: permissionsTags.REPORTS.ACTIVATION,
              },
              {
                title: 'Selo',
                href: '/reports/seal',
                permission: permissionsTags.REPORTS.SEAL,
              },
              {
                title: 'Consulta de Empresas',
                href: '/reports/companyQuery',
                permission: permissionsTags.REPORTS.COMPANY_QUERY,
              },
              {
                title: 'Tickets',
                href: '/reports/ticket',
                permission: permissionsTags.REPORTS.TICKET,
              },
            ],
          },
        ],
      },
      {
        items: [
          {
            main: true,
            title: 'Documentos',
            icon: ArchiveIcon,
            href: routes.documentsTemplate,
            items: [
              {
                title: 'Modelos',
                href: routes.documentsTemplate,
                permission: permissionsTags.DOCUMENTS_TEMPLATE.LIST,
              },
            ],
          },
        ],
      },
      {
        items: [
          {
            somePermissions: [
              permissionsTags.LAST_UPDATES.LIST,
              permissionsTags.LAST_UPDATES.SHOW,
              permissionsTags.LAST_UPDATES.CREATE,
              permissionsTags.LAST_UPDATES.EDIT,
              permissionsTags.LAST_UPDATES.DESTROY,
            ],
            main: true,
            title: 'Atualizações',
            icon: UploadIcon,
            href: '/last-updates',
          },
        ],
      },
      {
        items: [
          {
            somePermissions: [
              permissionsTags.DOCUMENTATION_LINKS.LIST,
              permissionsTags.PRIVACY_POLICY.LIST,
            ],
            main: true,
            title: 'Configurações',
            icon: SettingsIcon,
            items: [
              {
                title: 'Políticas de privacidade',
                permission: permissionsTags.PRIVACY_POLICY.LIST,
                href: routes.privacyPolicy.all,
              },
            ],
          },
        ],
      },
      {
        items: [
          {
            main: true,
            title: 'Apoio Técnico',
            icon: FeatherIcon,
            items: [
              {
                title: 'Base de conhecimento',
                href: routes.technicalFaqs.knowledgeBase,
              },
              {
                title: 'Subcategorias',
                href: routes.technicalFaqs.subcategory,
              },
            ],
          },
        ],
      },
      {
        items: [
          {
            somePermissions: [permissionsTags.FAQS_DOCUMENTATIONS.LIST],
            main: true,
            title: 'Central de Ajuda',
            icon: HelpIcon,
            items: [
              {
                title: 'FAQs',
                href: routes.faqs.all,
              },
              {
                title: 'Documentação',
                href: routes.documentations.all,
              },
            ],
          },
        ],
      },
      {
        items: [
          {
            main: true,
            title: 'Parceiros e Integrações',
            icon: CodeIcon,
            items: [
              {
                title: 'Planos',
                href: routes.integrationPlans.all,
              },
              {
                title: 'Parceiros',
                href: routes.integrationPartner.all,
              },
            ],
          },
        ],
      },
    ]

    setMenuItems(menuItems)
  }

  const loadUser = async () => {
    let response = await service.dponet.user.me()
    setUser(response?.data?.user)
    return response?.data?.user
  }

  const loadPermissions = async () => {
    const {
      data: { profilePermissions },
    } = await service.dponet.user.permissions()

    setPermissions(profilePermissions)
    loadHomePage(profilePermissions)
    return profilePermissions
  }

  const loadHomePage = (permissions) => {
    const homePage = helpers.auth.getHomePageByPermission(permissions)
    setHomePage(homePage)
  }

  const loadOut = () => {
    setLoaded(true)
    setIsLoading(false)
    setMenuItems([])
    setUser(null)
    setPermissions(null)
    setHomePage()
    setAuth(false)
    queryClient.invalidateQueries()
  }

  const loadDataWithAnimation = async () => {
    setIsLoading(true)
    setLoaded(false)
    try {
      await loadData()
    } finally {
      setLoaded(true)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (service.dponet.auth.isAuthenticated()) {
      loadDataWithAnimation()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        user,
        permissions,
        setUser,
        loaded,
        isLoading,
        menuItems,
        loadDataWithAnimation,
        loadData,
        loadUser,
        homePage,
        loadOut,
        loadPermissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
