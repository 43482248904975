import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { useSnackbar } from 'hooks'

import { BaseDialog } from 'components'
import { AddCommision } from '../'

import * as service from 'service'
import helpers from 'helpers'

const EditCommisionDialog = ({
  open,
  setOpen,
  commision,
  refresh,
  refreshSeller,
}) => {
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)

  const kind = commision?.kind || 'quantity'

  const { control, errors, watch, triggerValidation, getValues } = useForm({
    defaultValues: {
      kind: kind,
      level: commision?.level,
      rangeFrom: helpers.sellers.rangeType(
        commision?.kind,
        commision?.rangeFrom,
      ),
      rangeTo: helpers.sellers.rangeType(commision?.kind, commision?.rangeTo),
      percentage: helpers.sellers.rangeType(
        'percentage',
        commision?.percentage,
      ),
    },
  })

  const handleEdit = async () => {
    if (await triggerValidation()) {
      await onSubmit(getValues())
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await service.dponet.transferCommisions.update({
        transferCommisionId: commision?.id,
        ...helpers.sellers.mountTransfer({ ...data, kind }, null),
      })
      refresh()
      snackbar.open({
        message: 'Comissão atualizada com sucesso!',
        variant: 'success',
      })
      setOpen(false)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Ocorreu um erro ao atualizar a comissão',
        ),
        variant: 'error',
      })
    } finally {
      refreshSeller()
      setLoading(false)
    }
  }

  return (
    <BaseDialog
      actionButton={handleEdit}
      open={open}
      setOpen={setOpen}
      title="Editar Comissão"
      actionButtonText="Salvar"
      closeButtonText="Cancelar"
      actionButtonType="button"
      formRef="add-commision"
    >
      <form id="add-commision">
        <AddCommision
          control={control}
          errors={errors}
          loading={loading}
          watch={watch}
          isEdit
        />
      </form>
    </BaseDialog>
  )
}

export default EditCommisionDialog
