import { useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Button,
  Grid,
  Box,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core'
import Moment from 'moment'
import { Alert } from '@material-ui/lab'
import { Controller } from 'react-hook-form'

import { Card, Select } from 'components'
import { ManualDialog } from './components'

import constants from 'constants/index'

const ReadjustmentFidelity = ({
  disabled,
  control,
  errors,
  watch,
  paymentContract,
  refresh,
}) => {
  const [openManual, setOpenManual] = useState(false)
  const manual = watch('automatic') === false
  const lessOnethirdDays =
    !isEmpty(paymentContract?.endDate) &&
    Moment(paymentContract?.endDate).diff(Moment(), 'days') <= 29

  const handleManual = () => setOpenManual(true)

  return (
    <Card
      title="Reajuste & Fidelidade"
      actionButton={
        <>
          {manual && lessOnethirdDays && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleManual}
            >
              Reajustar o contato
            </Button>
          )}
        </>
      }
    >
      <Grid container spacing={2}>
        {lessOnethirdDays && (
          <Grid item xs={12}>
            <Alert variant="outlined" color={manual ? 'warning' : 'success'}>
              <Typography>O contrato está chegando ao fim!</Typography>
              <Typography variant="body2">
                {manual
                  ? 'A renovação do contrato deve ser feita manualmente e só pode ocorrer enquanto a cobrança ' +
                    'deste mês, referente à última parcela do contrato atual, já estiver sido gerada. Isso garante ' +
                    'que a cobrança seja gerada com o valor correto.'
                  : 'A renovação do contrato será feita automaticamente.'}
              </Typography>
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Controller
            disabled={disabled}
            control={control}
            name="periodOnMonth"
            mode="onBlur"
            type="number"
            as={
              <TextField
                label="Duração do contrato (Nº de meses)"
                fullWidth
                error={!!errors?.periodOnMonth}
                helperText={errors?.periodOnMonth?.message}
                InputProps={{
                  endAdornment: <Box mx={2}>Meses</Box>,
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            disabled={disabled}
            control={control}
            name="indexType"
            mode="onChange"
            type="text"
            as={
              <Select
                label="Índice de reajuste a ser utilizado"
                items={constants.paymentContract.INDEX_TYPE_OPTIONS}
                fullWidth
                error={!!errors?.indexType}
                helperText={errors?.indexType?.message}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={disabled}
            control={
              <Controller
                as={<Switch color="primary" />}
                name="automatic"
                control={control}
                onChange={([event]) => event.target.checked}
              />
            }
            label="Renovação automática"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={disabled}
            control={
              <Controller
                as={<Switch color="primary" />}
                name="fidelity"
                control={control}
                onChange={([event]) => event.target.checked}
              />
            }
            label="Fidelidade"
          />
        </Grid>
      </Grid>
      {openManual && (
        <ManualDialog
          open={openManual}
          setOpen={setOpenManual}
          paymentContract={paymentContract}
          refresh={refresh}
        />
      )}
    </Card>
  )
}

export default ReadjustmentFidelity
