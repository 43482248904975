import Moment from 'moment'

const statusDpoService = (paymentDpoService) => {
  if (!paymentDpoService || !paymentDpoService?.apply)
    return 'Não há um valor de DPO dedicado atribuído à mensalidade.'

  if (paymentDpoService?.apply) {
    if (Moment(paymentDpoService?.endDate) === Moment())
      return 'O serviço de DPO dedicado está ativo e a mensalidade já inclui o valor do DPO dedicado, mas a data de término é hoje.'

    if (Moment(paymentDpoService?.endDate) < Moment())
      return (
        'O serviço de DPO dedicado está ativo, mas a data de término pode já ter sido alcançada. Existe a ' +
        'possibilidade de que o valor da mensalidade ainda inclua o valor do DPO dedicado. Caso isso ocorra, deve-se ' +
        'remover o valor do DPO dedicado da mensalidade.'
      )

    return 'O serviço de DPO dedicado está ativo e a mensalidade já inclui o valor do DPO dedicado.'
  }
}
export default statusDpoService
