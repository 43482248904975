import * as yup from 'yup'

import constants from 'constants/index'

const schema = yup.object().shape({
  status: yup.boolean().required(),
  name: yup.string().required(),
  email: yup.string().email().required(),
  kind: yup.string().required(),
  document: yup.string().when('kind', {
    is: (kind) => kind !== constants.seller.SELLER_KIND,
    then: yup.string().required(),
  }),
  intervalInMonth: yup.number().when('kind', {
    is: (kind) => kind !== constants.seller.SELLER_KIND,
    then: yup.number().required().min(1).max(12),
  }),
})

export default schema
