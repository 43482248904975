import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Hidden, Typography } from '@material-ui/core'

import { Card } from 'components'
import { DialogDpoFantasy, DialogOutsourcedDpo } from './components'

import constants from 'constants/index'

const InviteAdvisor = ({
  refresh,
  company,
  invite,
  isEdit,
  advisorData,
  setAdvisorData,
  dpoType,
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [openDialogDpoFantasy, setOpenDialogDpoFantasy] = useState(false)

  const dpoOrganization = company?.dpoOrganization

  const inviteTypeText = {
    observer: 'DPO Observador',
    responsible: 'DPO Responsável',
    fantasy: 'DPO Fantasia',
  }[dpoType]

  const handleChangeDeleteOrOpenDialog = () => {
    if (dpoType === constants.inviteAdvisor.DPO_TYPE.FANTASY) {
      if (advisorData) {
        return setAdvisorData(null)
      }

      return setOpenDialogDpoFantasy(true)
    }

    if (advisorData) {
      setAdvisorData(null)
    } else {
      setOpenDialog(true)
    }
  }

  const handleOpenDialog = () => {
    if (dpoType === constants.inviteAdvisor.DPO_TYPE.FANTASY) {
      return setOpenDialogDpoFantasy(true)
    }

    setOpenDialog(true)
  }

  const inviteText = () => {
    const statusesMessages = {
      PENDING: (
        <>
          está com o convite pendente para se tornar <b>{inviteTypeText}</b>.
        </>
      ),
      REFUSED: (
        <>
          recusou o convite para se tornar <b>{inviteTypeText}</b>.
        </>
      ),
      CANCELED: 'está com o vínculo cancelado.',
      ACTIVE: (
        <>
          está registrado como o <b>{inviteTypeText}</b> dessa empresa.
        </>
      ),
    }
    if (!invite && isEdit)
      return (
        <>
          Essa empresa não possui um <b>{inviteTypeText}</b> registrado
        </>
      )
    else if (!isEdit && !advisorData) {
      return (
        <>
          Essa empresa não possui um <b>{inviteTypeText}</b> registrado
        </>
      )
    } else if (!isEdit && advisorData) {
      return (
        <>
          {advisorData?.email} será adicionado como&nbsp;
          <b>{inviteTypeText}</b> nessa empresa
        </>
      )
    } else if (
      dpoType === constants.inviteAdvisor.DPO_TYPE.FANTASY &&
      !!invite
    ) {
      return (
        <>
          <strong>{invite} </strong>
          {statusesMessages['ACTIVE']}
        </>
      )
    }
    return (
      <>
        <strong>{dpoOrganization?.dpo || invite?.name || invite?.email}</strong>
        &nbsp;
        {statusesMessages[invite.status]}
      </>
    )
  }

  return (
    <Box mt={4}>
      <Card title={inviteTypeText} paddingBottom={0}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" color="textPrimary">
            {inviteText()}
          </Typography>
          {isEdit ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenDialog}
            >
              {invite ? 'VISUALIZAR' : 'ADICIONAR'}&nbsp;
              <Hidden xsDown>{inviteTypeText}</Hidden>
            </Button>
          ) : (
            <Button
              variant="contained"
              color={advisorData ? 'secondary' : 'primary'}
              onClick={handleChangeDeleteOrOpenDialog}
            >
              {advisorData ? 'EXCLUIR' : 'ADICIONAR'}&nbsp;
              <Hidden xsDown>{inviteTypeText}</Hidden>
            </Button>
          )}
        </Box>
      </Card>
      <DialogOutsourcedDpo
        refresh={refresh}
        open={openDialog}
        setOpen={setOpenDialog}
        invite={invite}
        company={company}
        dpoType={dpoType}
        inviteTypeText={inviteTypeText}
        isEdit={isEdit}
        setAdvisorData={setAdvisorData}
      />
      <DialogDpoFantasy
        refresh={refresh}
        open={openDialogDpoFantasy}
        setOpen={setOpenDialogDpoFantasy}
        invite={invite}
        company={company}
        dpoType={dpoType}
        inviteTypeText={inviteTypeText}
        isEdit={isEdit}
        setAdvisorData={setAdvisorData}
      />
    </Box>
  )
}

InviteAdvisor.propTypes = {
  company: PropTypes.object,
  setOpenOutsourced: PropTypes.func,
  openOutsourced: PropTypes.bool,
  refresh: PropTypes.func,
  isEdit: PropTypes.bool,
  setOutsourcedDpoData: PropTypes.func,
  invite: PropTypes.string,
  dpoType: PropTypes.string,
}

InviteAdvisor.defaultProps = {
  setOpenOutsourced: () => {},
  refresh: () => {},
  setOutsourcedDpoData: () => {},
}

export default InviteAdvisor
