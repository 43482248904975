import moment from 'moment'
const mountDpoService = (data) => {
  return {
    endDate:
      data.dpoServiceApply && data.dpoServiceEndDate
        ? moment(data.dpoServiceEndDate).endOf('month').format('YYYY-MM-DD')
        : null,
    price: data.dpoServicePrice
      ? parseFloat(data.dpoServicePrice.replace(/\./g, '').replace(',', '.'))
      : null,
    apply: data.dpoServiceApply || false,
  }
}
export default mountDpoService
