import PropTypes from 'prop-types'
import {
  Box,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

import { routes } from 'Routes'
import constants from 'constants/index'
import helpers from 'helpers'

const ContractTable = ({ paymentContracts }) => {
  const history = useHistory()

  const redirectContract = (contractId) =>
    history.push(reverse(routes.contracts.show, { contractId }))

  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: true, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="15%" align="left">
                Nº do contrato
              </TableCell>
              <TableCell width="10%" align="left">
                Qtd de empresas
              </TableCell>
              <TableCell width="10%" align="left">
                Mensalidade
              </TableCell>
              <TableCell width="10%" align="left">
                Nível
              </TableCell>
              <TableCell width="10%" align="left">
                Porcentagem
              </TableCell>
              <TableCell width="10%" align="left">
                Situação
              </TableCell>
              <TableCell width="15%" align="left">
                Status da última cobrança
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentContracts?.map((paymentContract) => (
              <TableRow key={paymentContract?.id}>
                <TableCell>{paymentContract?.id}</TableCell>
                <TableCell>
                  <Link
                    href="#"
                    color="textPrimary"
                    onClick={() => redirectContract(paymentContract?.id)}
                  >
                    {paymentContract?.contractIdentifier}
                  </Link>
                </TableCell>
                <TableCell>{paymentContract?.preRegistrationCount}</TableCell>
                <TableCell>
                  R${' '}
                  {helpers.formatters.currencyInput(paymentContract?.price) ||
                    '-'}
                </TableCell>
                <TableCell>
                  {paymentContract?.transferCommision?.level || '-'}
                </TableCell>
                <TableCell>
                  {paymentContract?.transferCommision?.percentage || '-'} %
                </TableCell>
                <TableCell>
                  {constants.paymentContract.KIND_ACTIVE.includes(
                    paymentContract?.kind,
                  )
                    ? 'Ativo'
                    : 'Inativo'}
                </TableCell>
                <TableCell>
                  <Chip
                    color="primary"
                    align="center"
                    size="small"
                    label={helpers.paymentOrders.chipStatusLabel(
                      paymentContract?.lastPaymentOrderStatus,
                    )}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

ContractTable.propTypes = {
  paymentContracts: PropTypes.arrayOf(PropTypes.object),
}

export default ContractTable
