import { Box } from '@material-ui/core'

import { useFetch } from 'hooks'

import { Page, Container, ContentHeader, LoadingFeedback } from 'components'
import { Form, TransferCommision, ContractCard } from './components'

import * as service from 'service'
import constants from 'constants/index'

const Show = ({ match }) => {
  const { sellerId } = match.params

  const { response, isLoading } = useFetch(
    service.dponet.sellers.show,
    sellerId,
    [],
  )

  const seller = response?.data?.seller

  return (
    <>
      <LoadingFeedback open={isLoading} />
      {!isLoading && (
        <Page title="Visualização Vendedor & Parceiro">
          <Container maxWidth={false}>
            <ContentHeader
              title="Visualização"
              menu="Vendedor & Parceiro"
              subtitle={constants.seller.TRANSLATED_KINDS[seller.kind]}
            />
            <Form seller={seller} disabled />
            {seller?.kind !== constants.seller.SELLER_KIND && (
              <Box mt={2}>
                <TransferCommision seller={seller} disabled />
              </Box>
            )}
            <Box mt={2}>
              <ContractCard sellerId={seller?.id} />
            </Box>
          </Container>
        </Page>
      )}
    </>
  )
}
export default Show
