import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: 'none',
  },
  iconBtn: {
    cursor: 'pointer',
    margin: '0px 10px 0px 0px',
    display: 'flex',
  },
  iconBtnByRefresh: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))

export default useStyles
