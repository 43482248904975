import api from 'service/api'

const dponetAPI = api.create('dponet')

const create = async ({ ...data }) => {
  return await dponetAPI.post(`/churn_meetings`, {
    churnMeeting: data,
  })
}

const update = async ({ churnMeetingId, ...data }) => {
  return await dponetAPI.put(`/churn_meetings/${churnMeetingId}`, {
    churnMeeting: data,
  })
}

const churnMeetings = {
  create,
  update,
}

export default churnMeetings
