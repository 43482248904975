import formatters from 'helpers/formatters'
const companyTableGrid = (preRegistrations) => {
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'name',
      headerName: 'Nome',
      width: 500,
    },
    {
      field: 'document',
      headerName: 'CNPJ/CPF',
      width: 500,
    },
  ]

  const rows = preRegistrations.map((preRegistration) => {
    return {
      id: preRegistration?.id,
      name: preRegistration?.name || preRegistration?.fantasyName,
      document: formatters.cnpjAndCpfMask(preRegistration?.document),
    }
  })

  return {
    columns,
    rows,
  }
}
export default companyTableGrid
