import { Page, Container, ContentHeader, LoadingFeedback } from 'components'
import { Form, TransferCommision } from './components'
import { useFetch } from 'hooks'

import * as service from 'service'
import constants from 'constants/index'

import { Box } from '@material-ui/core'

const Edit = ({ match }) => {
  const { sellerId } = match.params

  const { response, isLoading, refresh } = useFetch(
    service.dponet.sellers.show,
    sellerId,
    [],
  )

  const seller = response?.data?.seller

  return (
    <>
      <LoadingFeedback open={isLoading} />
      {!isLoading && (
        <Page title="Editar Vendedor & Parceiro">
          <Container maxWidth={false}>
            <ContentHeader
              title="Editar"
              menu="Vendedor & Parceiro"
              subtitle={constants.seller.TRANSLATED_KINDS[seller.kind]}
            />
            <Form seller={seller} />
            {seller?.kind !== constants.seller.SELLER_KIND && (
              <Box mt={2}>
                <TransferCommision seller={seller} refreshSeller={refresh} />
              </Box>
            )}
          </Container>
        </Page>
      )}
    </>
  )
}
export default Edit
