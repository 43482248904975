import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  inputImageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px ${theme.palette.primary.main} dashed`,
    borderRadius: '5px',
    color: theme.palette.primary.main,
    flexDirection: 'column',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  buttonDownloadModel: {
    margin: '20px 0',
    width: '30%',
    paddingInline: '12px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export default styles
