import helpers from 'helpers'

const rangeType = (kind, value = 0) => {
  if (kind === 'quantity') return parseInt(value) || 0
  if (kind === 'percentage') {
    return value || ''
  }

  return helpers.formatters.currencyInput(value || '00.00')
}

export default rangeType
