import PropTypes from 'prop-types'
import { useState } from 'react'
import { Button } from '@material-ui/core'

import { MenuButton, LoadingFeedback, ButtonLogs } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import incidentReportPdf from 'helpers/reports/simplifiedIncident/incidentReportPdf'
import completeIncidentReportPdf from 'helpers/reports/completeIncident/completeIncidentReportPdf'

import * as service from 'service'

const IncidentMenuItems = ({ incident, ...rest }) => {
  const snackbar = useSnackbar()

  const [loadingExportReport, setLoadingExportReport] = useState(false)

  const incidentId = incident?.id
  const companyId = incident?.companyId
  const simplifiedForm = incident?.simplifiedForm
  const generateIncidentReport = async () => {
    try {
      setLoadingExportReport(true)
      const [{ data: incident }, { data: company }] = await Promise.all([
        service.dponet.incidents.show({ incidentId }),
        service.dponet.companies.get({ companyId }),
      ])

      simplifiedForm
        ? await incidentReportPdf(company?.company, incident)
        : await completeIncidentReportPdf(company?.company, incident)
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.message ||
          'Erro ao gerar o Relatório de Incidentes simplificado',
        variant: 'error',
      })
    } finally {
      setLoadingExportReport(false)
    }
  }

  const items = [
    { label: 'EXPORTAR RELATÓRIO', action: generateIncidentReport },
  ]

  return (
    <>
      <LoadingFeedback open={loadingExportReport} />
      <MenuButton iconButton={false} {...rest}>
        <ButtonLogs entityId={incidentId} routes="incidents" />
        {items.map((item) => (
          <Button key={item.label} fullWidth onClick={item.action}>
            {item.label}
          </Button>
        ))}
      </MenuButton>
    </>
  )
}

IncidentMenuItems.propTypes = {
  incident: PropTypes.object,
}

export default IncidentMenuItems
