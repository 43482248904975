import helpers from 'helpers'

const option = (plans) => {
  if (!plans) return []

  return plans.map((plan) => {
    return {
      ...plan,
      name: `${plan?.name} - ${
        plan?.code
      } - R$${helpers.formatters.currencyInput(plan?.price)}`,
    }
  })
}

export default option
