import { useState } from 'react'
import {
  Button,
  Box,
  Card,
  Divider,
  Grid,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  LoadingFeedback,
  MenuButton,
  Page,
  Permitted,
} from 'components'
import {
  PaymentOrdersTable,
  ExportConciliationPaymentsDialog,
  ExportPaymentsDialog,
} from './components'

import * as service from 'service'
import constants from 'constants/index'

const PaymentOrdersMain = () => {
  const filter = useFilter()
  const [open, setOpen] = useState(false)
  const [openReport, setOpenReport] = useState(false)

  const theme = useTheme()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.dponet.paymentOrders.lists,
    {
      ...filter.filters,
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  const { response: responseSeller, isLoading: loadSeller } = useFetch(
    service.dponet.sellers.index,
    {
      kindNotIn: [constants.seller.PARTNER_KIND],
    },
  )

  const handleOpen = () => setOpen(true)
  const handleExportInvoice = () => setOpenReport(true)

  return (
    <>
      <Page title="Ordem de pagamentos">
        <LoadingFeedback open={isLoading || loadSeller} />
        <Container maxWidth={false}>
          <ContentHeader
            title="Listagem"
            menu="Ordem de pagamentos"
            subtitle="Listagem de ordem de pagamentos"
            filter={filter}
            notSpecificTags
          >
            <Permitted
              tag={constants.permissions.PAYMENT_ORDERS.MANAGE_PAYMENT}
            >
              <Box mr={1}>
                <MenuButton iconButton={false} title="Exportar">
                  <Button onClick={handleOpen} color="primary">
                    Conciliação
                  </Button>
                  <Button onClick={handleExportInvoice} color="primary">
                    Relatório
                  </Button>
                </MenuButton>
              </Box>
            </Permitted>
            <Box ml={1}>
              <FilterButton setDrawerOpen={filter.setDrawerOpen} />
            </Box>
          </ContentHeader>
          <Card>
            <Box width="100%">
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    width="100%"
                    p={2}
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5">Ordem de pagamentos</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
            </Box>
            <PaymentOrdersTable
              paymentOrders={response?.data?.paymentOrders || []}
              refresh={refresh}
            />
            <Box px={2} display="flex" justifyContent="flex-end">
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Box>
          </Card>
        </Container>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input
            textfieldinput="true"
            label="Nome do cliente/sublicenciamento"
            name="buyerName"
          />
          <input
            textfieldinput="true"
            label="E-mail do cliente/sublicenciamento"
            name="buyerEmail"
          />
          <input
            textfieldinput="true"
            label="CNPJ/CPF do cliente/sublicenciamento"
            name="buyerDocument"
          />
          <input
            textfieldinput="true"
            label="Nº do contrato"
            name="contractIdentifier"
          />
          <input
            datepickerinput="true"
            label="Data de criação (maior ou igual)"
            name="createdAtGteq"
          />
          <input
            datepickerinput="true"
            label="Data de criação (menor ou igual)"
            name="createdAtLteq"
          />
          <input
            datepickerinput="true"
            label="Data da confirmação de pagamento"
            name="paidDateEq"
          />
          <input
            datepickerinput="true"
            label="Data da disponibilidade do saque "
            name="withdrawalDateEq"
          />
          <input
            datepickerinput="true"
            label="Data de vencimento"
            name="expirationDateEq"
          />
          <select
            autocompleteselectinput="true"
            label="Método de pagamento"
            name="paymentMethod"
            optionvalues={constants.paymentOrder.PAYMENT_METHODS}
          />
          <select
            multipleselectinput="true"
            label="Status"
            name="statusIn"
            optionvalues={constants.paymentOrder.STATUS_OPTIONS}
          />

          <select
            autocompleteselectinput="true"
            compareByName
            name="sellerId"
            label="Executivo de vendas"
            optionvalues={responseSeller?.data?.sellers || []}
          />
          <select
            multipleselectinput="true"
            label="Intermediador (Gateway)"
            name="gatewayIn"
            optionvalues={constants.paymentOrder.GATEWAYES}
          />
          <select
            multipleselectinput="true"
            label="Etapa de pagamento concluída"
            name="paymentStepIn"
            optionvalues={constants.paymentOrder.STEPS_LISTS}
          />
          <select
            multipleselectinput="true"
            label="Etapa de pagamento não concluída"
            name="paymentStepNotIn"
            optionvalues={constants.paymentOrder.STEPS_LISTS}
          />
          <input name="paymentStep" />
        </Filters>
      </Page>
      {open && (
        <ExportConciliationPaymentsDialog open={open} setOpen={setOpen} />
      )}
      {openReport && (
        <ExportPaymentsDialog
          open={openReport}
          setOpen={setOpenReport}
          params={filter.filters}
        />
      )}
    </>
  )
}

export default PaymentOrdersMain
