import PropTypes from 'prop-types'

import clsx from 'clsx'

import { Chip, IconButton } from '@material-ui/core'
import { Trash2 } from 'react-feather'

import { TableRow, TableCell } from 'components/Table'
import { Permitted } from 'components'

import permission from 'constants/permissions'

import useStyles from './styles'

const DepartmenUserRow = ({ user, open, setOpen, setUserData, ...rest }) => {
  const classes = useStyles()

  const handleRemove = (user) => {
    setUserData(user)
    setOpen(!open)
  }

  return (
    <TableRow {...rest}>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell disableTooltip>
        <Chip
          className={clsx(classes.root, {
            [classes.active]: user.active,
            [classes.inactive]: !user.active,
          })}
          align="center"
          size="small"
          label={user.active ? 'Ativo' : 'Inativo'}
        />
      </TableCell>
      <TableCell align="right" disableTooltip>
        <Permitted tag={permission.DEPARTMENT.CREATE}>
          <IconButton
            onClick={() => handleRemove(user)}
            aria-label="Remover"
            variant="outlined"
            color="secondary"
          >
            <Trash2 />
          </IconButton>
        </Permitted>
      </TableCell>
    </TableRow>
  )
}

DepartmenUserRow.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setUserData: PropTypes.func,
}

DepartmenUserRow.defaultProps = {
  open: false,
  setOpen: () => {},
  setUserData: () => {},
}

export default DepartmenUserRow
