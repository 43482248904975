import { useState } from 'react'
import { Alert } from '@material-ui/lab'
import { Box, Typography } from '@material-ui/core'

import { useFetch } from 'hooks'

import { Container, ContentHeader, LoadingFeedback, Page } from 'components'
import { ChurnStepper } from './components'

import * as service from 'service'

const ChurnsEdit = ({ match }) => {
  const {
    params: { churnId },
  } = match

  const [loading, setLoading] = useState(false)

  const { response, isLoading } = useFetch(
    service.dponet.churns.show,
    { churnId },
    [],
  )

  const churn = isLoading ? {} : response?.data?.churn

  return (
    <Page title="Edição de churn">
      {isLoading ? (
        <LoadingFeedback open={isLoading || loading} />
      ) : (
        <Container maxWidth={false}>
          <ContentHeader
            menu="Edição de churn"
            title="Edição"
            subtitle="Edição de churn"
          />
          {churn?.paymentContract?.fidelity && (
            <Box mb={4}>
              <Alert variant="outlined" severity="warning">
                <Typography variant="body1">
                  Atenção! Este contrato possui fidelidade.
                </Typography>
              </Alert>
            </Box>
          )}
          {churn?.automatic && (
            <Box mb={4}>
              <Alert variant="filled" severity="info">
                <Typography variant="body1">
                  Este churn foi gerado automaticamente pelo sistema.
                </Typography>
              </Alert>
            </Box>
          )}
          <ChurnStepper churn={churn} setLoading={setLoading} />
        </Container>
      )}
    </Page>
  )
}

export default ChurnsEdit
