import permissions from '../../permissions'

const FINANCIAL_LIST = [
  {
    name: 'Acesso ao painel financeiro',
    tag: permissions.FINANCIAL_CLIENT.MANAGE,
  },
]

export default FINANCIAL_LIST
