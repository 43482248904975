import api from 'service/api'

const dponetAPI = api.create('dponet')

const index = async ({ ...params }) => {
  return await dponetAPI.get('/transfer_commisions', { params })
}

const show = async ({ transferCommisionId = '' }) => {
  return await dponetAPI.get(`/transfer_commisions/${transferCommisionId}`)
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/transfer_commisions', {
    transferCommision: data,
  })
}

const update = async ({ transferCommisionId = '', ...data }) => {
  return await dponetAPI.put(`/transfer_commisions/${transferCommisionId}`, {
    transferCommision: data,
  })
}

const destroy = async ({ transferCommisionId }) => {
  return await dponetAPI.delete(`/transfer_commisions/${transferCommisionId}`)
}

const transferCommisions = {
  index,
  show,
  create,
  update,
  destroy,
}

export default transferCommisions
