import { useFetch } from 'hooks'

import { Filters } from 'components'

import constants from 'constants/index'
import * as service from 'service'

const ContractsFilter = ({ filter }) => {
  const { response: responseSeller, isLoading: loadSeller } = useFetch(
    service.dponet.sellers.index,
    {
      kindNotIn: [constants.seller.PARTNER_KIND],
    },
  )

  const { response: responsePartner, isLoading: loadPartner } = useFetch(
    service.dponet.sellers.index,
    {
      kindNotIn: [constants.seller.SELLER_KIND],
    },
  )

  return (
    <Filters filter={filter}>
      <input textfieldinput="true" label="Identificador" name="id" />
      <input
        textfieldinput="true"
        label="Cliente/Sublicenciamento"
        placeholder="ID, Nome ou CNPJ/CPF"
        name="identifierOrDocumentCont"
      />
      <input
        textfieldinput="true"
        label="Nº do contrato"
        name="contractIdentifier"
      />
      <select
        textfieldinput="true"
        label="Método de pagamento"
        name="methodPayment"
      >
        <option value=""></option>
        <option value="billet">Boleto</option>
        <option value="card">Cartão</option>
        <option value="pix">Pix</option>
      </select>
      <select textfieldinput="true" label="Status" name="kind">
        <option value=""></option>
        <option value="initial">Em implementação</option>
        <option value="recurrence">Recorrência</option>
        <option value="manual">Manual</option>
        <option value="canceled">Cancelado</option>
        <option value="duplicated">Duplicado (temporário)</option>
        <option value="suspended">Suspenso</option>
      </select>
      <select textfieldinput="true" label="Gateway" name="gatewayEq">
        <option value=""></option>
        <option value="superfin">Superfin</option>
        <option value="omie">OMIE</option>
      </select>
      <input
        datepickerinput="true"
        label="Data de faturamento"
        name="nextDateAdvanceEq"
      />
      <input
        textfieldinput="true"
        type="number"
        label="Dia de faturamento"
        name="daysAdvanceEq"
      />
      <select
        textfieldinput="true"
        label="Tipo de vencimento"
        name="typeBillingDayEq"
      >
        <option value=""></option>
        <option value="fixed">Dia fixo</option>
        <option value="dynamic">Nº de dias após faturar</option>
      </select>
      <input
        textfieldinput="true"
        type="number"
        label="Dia de vencimento fixo"
        name="billingDayEq"
      />
      <input
        textfieldinput="true"
        type="number"
        label="Dia de vencimento dinâmico"
        name="billingDayDynamicEq"
      />
      <select
        autocompleteselectinput="true"
        compareById
        name="sellerId"
        label="Executivo de vendas"
        loading={loadSeller}
        optionvalues={responseSeller?.data?.sellers || []}
      />
      <select
        autocompleteselectinput="true"
        compareById
        name="partnerIdEq"
        label="Parceiro de vendas"
        loading={loadPartner}
        optionvalues={responsePartner?.data?.sellers || []}
      />
      <input
        datepickerinput="true"
        label="Data de termino maior ou igual a"
        name="endDateGteq"
      />
      <input
        datepickerinput="true"
        label="Data de termino menor ou igual a"
        name="endDateLteq"
      />
      <select
        textfieldinput="true"
        label="Renovação automatica"
        name="renewalAutomaticEq"
      >
        <option value=""></option>
        <option value="true">Sim</option>
        <option value="false">Não</option>
      </select>
      <select
        textfieldinput="true"
        label="Serviço DPO dedidado"
        name="dpoServiceApplyEq"
      >
        <option value=""></option>
        <option value="true">Sim</option>
        <option value="false">Não</option>
      </select>
      <select textfieldinput="true" label="Fidelidade" name="fidelityApplyEq">
        <option value=""></option>
        <option value="true">Sim</option>
        <option value="false">Não</option>
      </select>
      <input name="paymentStep" />
    </Filters>
  )
}

export default ContractsFilter
