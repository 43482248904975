import { Grid, TextField } from '@material-ui/core'

import { Controller } from 'react-hook-form'

import { Card } from 'components'

const Observation = ({ control, errors, isShow }) => {
  return (
    <Card title="Observações" dropdown dropdownChildrenPadding={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            disabled={isShow}
            control={control}
            name="terms"
            mode="onBlur"
            type="text"
            as={
              <TextField
                label="Observações externa (nota fiscal)"
                multiline
                minRows={5}
                fullWidth
                variant="outlined"
                error={!!errors?.terms}
                helperText={errors?.terms?.message}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            disabled={isShow}
            control={control}
            name="observations"
            mode="onChange"
            type="text"
            as={
              <TextField
                label="Todos os comentários feitos nessa seção serão encaminhados para a equipe de CS (onboarding) e ficará registrado no OMIE"
                multiline
                minRows={5}
                fullWidth
                variant="outlined"
                error={!!errors?.observations}
                helperText={errors?.observations?.message}
              />
            }
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default Observation
