import { useFetch } from 'hooks'

import { Form } from './components'
import { Container, ContentHeader, Page, LoadingFeedback } from 'components'

import * as service from 'service'

const PaymentOrdersEdit = ({ match }) => {
  const {
    params: { paymentOrderId },
  } = match

  const { response, isLoading } = useFetch(
    service.dponet.paymentOrders.show,
    { paymentOrderId },
    [],
  )

  return (
    <Page title="Editar ordem de pagamento">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth={false}>
        <ContentHeader
          menu="Ordem de pagamento"
          title="Editar ordem de pagamento"
          subtitle="Editar ordem de pagamento"
        />
        {!isLoading && <Form paymentOrder={response?.data?.paymentOrder} />}
      </Container>
    </Page>
  )
}

export default PaymentOrdersEdit
