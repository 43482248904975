import {
  Box,
  TableContainer,
  TablePagination,
  useMediaQuery,
} from '@material-ui/core'

import { ButtonComponent, Card, LoadingFeedback } from 'components'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'

import DelinquencyTableRow from '../DelinquencyTableRow'

import useFetch from 'hooks/useFetch'
import { usePagination } from 'hooks'
import theme from 'theme'

import * as service from 'service'
import PropTypes from 'prop-types'

const DelinquencyTable = ({ churnId, handleBackPage, button = false }) => {
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.dponet.churns.overduePayment,
    {
      churnId,
      perPage,
      page,
    },
    [page, perPage],
  )

  const paymentOrders = response?.data?.paymentOrders || []

  return (
    <>
      <Card title="Cobranças" fromTable>
        <LoadingFeedback open={isLoading} />
        <TableContainer>
          <Table emptyMessage="Nenhuma cobrança foi encontrada.">
            <TableHead>
              <TableRow>
                <TableCell width="20%">Mês de referência</TableCell>
                <TableCell width="40%" noOverFlow>
                  Data do vencimento do boleto
                </TableCell>
                <TableCell width="40%" align="center">
                  Valor
                </TableCell>
                <TableCell width="40%" align="center">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentOrders.map((paymentOrder) => (
                <DelinquencyTableRow
                  paymentOrder={paymentOrder}
                  refresh={refresh}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box px={2} display="flex" justifyContent="flex-end">
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={isDesktop ? 'Por página' : ''}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </Box>
      </Card>
      {button && (
        <Box
          display="flex"
          justifyContent="flex-end"
          gridGap={theme.spacing(2)}
        >
          <ButtonComponent
            variant="contained"
            color="primary"
            onClick={handleBackPage}
            title="Confirmar"
          />
        </Box>
      )}
    </>
  )
}

DelinquencyTable.propTypes = {
  churnId: PropTypes.number,
  handleBackPage: PropTypes.func,
  button: PropTypes.bool,
}

export default DelinquencyTable
