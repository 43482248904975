import { useState } from 'react'
import {
  Button,
  Box,
  Card,
  Divider,
  Grid,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Plus as PlusIcon } from 'react-feather'
import { useHistory } from 'react-router-dom'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import {
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
  Page,
  Permitted,
} from 'components'
import { ContractsTable, ContractsFilter, ExportDialog } from './components'
import * as service from 'service'
import { routes } from 'Routes'
import constants from 'constants/index'

const ContractsMain = () => {
  const history = useHistory()
  const filter = useFilter()

  const [openReport, setOpenReport] = useState(false)

  const theme = useTheme()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.dponet.paymentContract.lists,
    {
      ...filter.filters,
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  const handleNew = () => history.push(routes.contracts.new)
  const handleExportInvoice = () => setOpenReport(true)

  return (
    <>
      <Page title="Contratos">
        <LoadingFeedback open={isLoading} />
        <Container maxWidth={false}>
          <ContentHeader
            title="Listagem"
            menu="Contratos"
            subtitle="Listagem dos Contratos"
            filter={filter}
            notSpecificTags
          >
            <Permitted tag={constants.permissions.PAYMENT_CONTRACTS.MANAGE}>
              <Box mr={1}>
                <Button
                  onClick={handleExportInvoice}
                  color="primary"
                  variant="contained"
                >
                  Relatório
                </Button>
              </Box>
            </Permitted>
            <Box ml={1}>
              <FilterButton setDrawerOpen={filter.setDrawerOpen} />
            </Box>
          </ContentHeader>
          <Card>
            <Box width="100%">
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    width="100%"
                    p={2}
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5">Contratos</Typography>
                    <Permitted
                      tag={constants.permissions.PAYMENT_CONTRACTS.MANAGE}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<PlusIcon />}
                        onClick={handleNew}
                      >
                        Criar
                      </Button>
                    </Permitted>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
            </Box>
            <ContractsTable
              paymentContracts={response?.data?.paymentContracts || []}
              refresh={refresh}
            />
            <Box px={2} display="flex" justifyContent="flex-end">
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Box>
          </Card>
        </Container>
        {filter.drawerOpen && <ContractsFilter filter={filter} />}
      </Page>
      {openReport && (
        <ExportDialog
          open={openReport}
          setOpen={setOpenReport}
          params={filter.filters}
        />
      )}
    </>
  )
}

export default ContractsMain
