import {
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { DatePicker } from '@material-ui/pickers'

import { Card } from 'components'

import { Controller } from 'react-hook-form'

import helpers from 'helpers'

const DpoService = ({ disabled, control, errors, watch }) => {
  return (
    <Card title="DPO dedicado">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={disabled}
            control={
              <Controller
                as={<Switch color="primary" />}
                name="dpoServiceApply"
                control={control}
                onChange={([event]) => event.target.checked}
              />
            }
            label="Serviço de DPO dedicado"
          />
        </Grid>
        {watch('dpoServiceApply') && (
          <>
            <Grid item xs={12}>
              <Alert severity="info" variant="outlined">
                <Typography variant="body1">
                  Com a ativação do serviço de DPO dedicado, o valor da
                  mensalidade será ajustado automaticamente. Ao término do
                  serviço, o custo correspondente será removido, retornando ao
                  valor anterior.
                </Typography>
              </Alert>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={
                  <DatePicker
                    label="Término do serviço"
                    color="primary"
                    format="MM/yyyy"
                    variant="dialog"
                    disablePast
                    views={['year', 'month']}
                    error={!!errors.dpoServiceEndDate}
                    helperText={errors?.dpoServiceEndDate?.message}
                    fullWidth
                    onChange={(newValue) => ({ value: newValue })}
                  />
                }
                name="dpoServiceEndDate"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={
                  <TextField
                    label="Valor do serviço"
                    color="primary"
                    error={!!errors.dpoServicePrice}
                    helperText={errors?.dpoServicePrice?.message}
                    fullWidth
                  />
                }
                name="dpoServicePrice"
                onChange={([event]) => {
                  return helpers.formatters.currencyInput(event.target.value)
                }}
                control={control}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  )
}

export default DpoService
