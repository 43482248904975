import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  btn: {
    height: '100%',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      width: '0',
    },
  },
  removeBtn: {
    height: '100%',
    padding: 0,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}))
export default styles
