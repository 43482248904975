import * as yup from 'yup'

const schema = yup.object().shape({
  segmentId: yup.string().required(),
  sellerId: yup.string().required(),
  planId: yup.string().required(),
  companyTemplate: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
  }),
})

export default schema
